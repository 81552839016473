import React, { Component } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import numeral from "numeral";

const Count = styled.div`
  font-family: Nunito;
  font-size: 22px;
  font-weight: bold;
  background: ${(props) => `linear-gradient(${props.color1}, ${props.color2})`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0; /* Ensure the count stays above other elements */
`;

const GradientWrapper = styled.div`
  position: relative;
  width: 120px; /* Adjust width to fit within the card */
  height: 120px; /* Adjust height to fit within the card */
  margin: 0 auto; /* Center the wrapper horizontally */
  .CircularProgressbar {
    width: 100%; /* Ensure the circular progress bar fits the wrapper */
    height: 100%; /* Ensure the circular progress bar fits the wrapper */
  }
`;

const Title = styled.div`
  font-family: Nunito;
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 21.74px;
  text-align: center;
`;

const CardWithBorder = styled(Card)`
  border-radius: 10px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  border: none;
  position: relative;
  height: 180px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6px;
    background: ${(props) =>
      `linear-gradient(to right, ${props.color1}, ${props.color2})`};
    border-radius: 0 0 10px 10px;
  }
`;

class ReportStatistics extends Component {
  render() {
    const { title, color1, color2, percentage, count, gradientId } = this.props;

    return (
      <CardWithBorder
        color1={color1}
        color2={color2}
        gradientId={gradientId}
        className="text-center mb-3"
      >
        <Title>{title}</Title>
        <GradientWrapper gradientId={gradientId}>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stopColor={color1} />
                <stop offset="100%" stopColor={color2} />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgressbar
            value={percentage}
            styles={buildStyles({
              pathColor: `url(#${gradientId})`,
              trailColor: "#e0e0e0",
              strokeLinecap: "round",
              rotation: 0.3,
              pathTransitionDuration: 0.5,
            })}
          />
          <Count color1={color1} color2={color2}>
            {numeral(count).format("0.[0]a").toUpperCase()}
          </Count>
        </GradientWrapper>
      </CardWithBorder>
    );
  }
}

export default ReportStatistics;
