import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";
import { UncontrolledTooltip } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "reactstrap";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
var moment = require("moment");

class ViewResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      library: {
        UniversityName: "Fetching...",
      },
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      resource: null,
      errMsg: null,
    };
  }

  getResource = (id) => {
    var url = new URL(`${API_BASE}resources/${id}`);
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            resource: res.data,
          });
        } else {
          this.setState({ loading: false, errMsg: res.msg });
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  getResourceCategories = () => {
    var data = [];
    if (!this.state.resource.ResourceCategories) {
      return [];
    } else {
      var step = this.state.resource.ResourceCategories;
      while (step) {
        data.push(step.Name);
        step = step.ParentResourceCategory;
      }
      return data.reverse();
    }
  };

  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getLibrary(this.props.match.params.libid);
    this.getResource(this.props.match.params.id);
  }

  getParentBreadcrumb = () => {
    if (
      this.state.loggedInUser.Role.Name == "Master Admin" ||
      this.state.loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: this.state.library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`,
        },
        {
          label: "Resources",
          link: `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resources/${this.props.location.search}`,
        },
      ];
    } else {
      return [
        {
          label: "Resources",
          link: `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resources/${this.props.location.search}`,
        },
      ];
    }
  };

  render() {
    if (this.state.library == 404) {
      return (
        <Fragment>
          <Breadcrumb
            title={`View Resource`}
            parentLink={`${process.env.PUBLIC_URL}/libraries`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
                this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>Library not found!</p>
              <hr />
              <p className="mb-0">
                {(this.state.loggedInUser.Role.Name == "Master Admin" ||
                  this.state.loggedInUser.Role.Name ==
                  "Organization Admin") && (
                    <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                      Back to Libraries
                    </Link>
                  )}
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title="View Resource"
            specialParent={this.getParentBreadcrumb()}
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    if (this.state.errMsg) {
      return (
        <Fragment>
          <Breadcrumb
            title="View Resource"
            specialParent={this.getParentBreadcrumb()}
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>{this.state.errMsg}</p>
              <hr />
              <p className="mb-0">
                <Link to={`${process.env.PUBLIC_URL}/resources`}>
                  Back to Resources
                </Link>
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Breadcrumb
          title="View Resource"
          specialParent={this.getParentBreadcrumb()}
        />
        <Button
          style={{ float: "right", marginTop: -60, marginRight: 16 }}
          color="primary"
          onClick={() =>
            this.props.history.push(
              `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resource/edit/${this.props.match.params.id}`
            )
          }
        >
          <i className="fa fa-pencil"></i> EDIT
        </Button>
        <div className="container-fluid">
          <div className="card p-4">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="blog-single">
                  <div className="blog-box blog-details">
                    <img
                      className="img-fluid"
                      src={require("../../../assets/images/ebook.png")}
                      alt="blog-main"
                      style={{ width: 120 }}
                    />
                    <div className="blog-details mt-1">
                      <ul className="blog-social">
                        <li
                          className="digits"
                          style={{
                            border: "none",
                            color: "#007bff",
                            paddingRight: 0,
                            cursor: "pointer",
                          }}
                          id="createdOn"
                        >
                          <i className="fa fa-clock-o"></i>
                          {moment(this.state.resource.CreatedOn).format(
                            "D MMM YYYY"
                          )}
                        </li>
                        <UncontrolledTooltip
                          placement="bottom"
                          target="createdOn"
                        >
                          Created On
                        </UncontrolledTooltip>
                        <li
                          id="createdBy"
                          className="digits"
                          style={{
                            marginRight: 40,
                            color: "#007bff",
                            cursor: "pointer",
                          }}
                        >
                          <i className="icofont icofont-user"></i>
                          {this.state.resource.CreatedBy.UserName}
                        </li>
                        <UncontrolledTooltip
                          placement="bottom"
                          target="createdBy"
                        >
                          Created By
                        </UncontrolledTooltip>
                        {this.state.resource.UpdatedOn && (
                          <Fragment>
                            <li
                              id="updatedOn"
                              className="digits"
                              style={{
                                borderLeft: "1px solid #777777",
                                color: "#ff9f40",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-clock-o"></i>
                              {moment(this.state.resource.UpdatedOn).format(
                                "D MMM YYYY"
                              )}
                            </li>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="updatedOn"
                            >
                              Updated On
                            </UncontrolledTooltip>
                          </Fragment>
                        )}
                        {this.state.resource.UpdatedBy && (
                          <Fragment>
                            <li
                              id="updatedBy"
                              className="digits"
                              style={{ color: "#ff9f40", cursor: "pointer" }}
                            >
                              <i className="icofont icofont-user"></i>
                              {this.state.resource.UpdatedBy.UserName}
                            </li>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="updatedBy"
                            >
                              Updated By
                            </UncontrolledTooltip>
                          </Fragment>
                        )}
                      </ul>
                      <h4>{this.state.resource.Name}</h4>
                    </div>
                  </div>
                  <section className="comment-box">
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <h5>Code</h5>
                      </div>
                      <div className="col-md-12">
                        <p>
                          <code>{this.state.resource.Code}</code>
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <h5>Resource Category</h5>
                      </div>
                      <div className="col-md-12">
                        <p>
                          {this.getResourceCategories().map((v, i) => {
                            var dashes = [];
                            for (var index = i; i > 0; i--) {
                              dashes.push(
                                <span key={i + Math.random()}>- </span>
                              );
                            }
                            return (
                              <code key={i + Math.random()}>
                                {" "}
                                {dashes} {v} <br />
                              </code>
                            );
                          })}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <h5>Description</h5>
                      </div>
                      <div className="col-md-12">
                        <p
                          style={{ color: "#1ea6ec" }}
                          dangerouslySetInnerHTML={{
                            __html: this.state.resource.Description.replace(
                              /\r\n|\r|\n/g,
                              "<br />"
                            ),
                          }}
                        ></p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <h5>Meta</h5>
                      </div>
                      <div className="col-md-12">
                        <p
                          style={{ color: "#1ea6ec" }}
                          dangerouslySetInnerHTML={{
                            __html: this.state.resource.Meta.replace(
                              /\r\n|\r|\n/g,
                              "<br />"
                            ),
                          }}
                        ></p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <section className="comment-box">
            <Link
              className="mb-5"
              to={`${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resources/${this.props.location.search}`}
            >
              <i className="fa fa-arrow-circle-left"></i> Back to Resources
            </Link>
          </section>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  dropdown: {
    float: "right",
  },
  pagination: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ViewResource;
