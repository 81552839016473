import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { API_BASE } from "../../../constant/api";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

const EditResource = ({ match }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState({
    Role: {
      IsAdmin: false,
      Name: "",
    },
  });
  const history = useHistory();
  const [resource, setResource] = useState(null);
  const [resourceCategories, setResourceCategories] = useState(null);
  const [library, setLibrary] = useState({
    UniversityName: "Fetching...",
  });
  const [syncLoading, setsyncLoading] = useState(false);

  const getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLibrary(res.data);
        } else {
          setLibrary(404);
        }
      });
  };

  const getResource = (id) => {
    var url = new URL(`${API_BASE}resources/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setResource(res.data);
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const getResourceCategories = () => {
    fetch(`${API_BASE}resourceCategories?library_id=${match.params.libid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setResourceCategories(res.data);
        }
      });
  };

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    getLibrary(match.params.libid);
    getResource(match.params.id);
    getResourceCategories();
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      if (data.ResourceCategories == "select") {
        delete data["ResourceCategories"];
      }
      data = { ...data, _id: match.params.id, Library: match.params.libid };
      setEditing(true);
      fetch(`${API_BASE}resources`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setEditing(false);
          if (!res.success) {
            setError(res.msg);
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(
              `${process.env.PUBLIC_URL}/library/${match.params.libid}/resources`
            );
          }
        })
        .catch((err) => {
          setEditing(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  const syncWithMasterResource = () => {
    setsyncLoading(true);
    var url = new URL(
      `${API_BASE}masterResources/${resource.MasterResource._id}`
    );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setsyncLoading(false);
        if (res.success) {
          setValue("Name", res.data.Name);
          setValue("Code", res.data.Code);
          setValue("Description", res.data.Description);
          setValue("Meta", res.data.Meta);
        }
      })
      .catch((err) => {
        setsyncLoading(false);
      });
  };

  const getParentBreadcrumb = () => {
    if (
      loggedInUser.Role.Name == "Master Admin" ||
      loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${match.params.libid}`,
        },
        {
          label: "Resources",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/resources`,
        },
      ];
    } else {
      return [
        {
          label: "Resources",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/resources`,
        },
      ];
    }
  };

  if (library == 404) {
    return (
      <Fragment>
        <Breadcrumb
          title="Edit Resource"
          parentLink={`${process.env.PUBLIC_URL}/libraries`}
          parent={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? "Libraries"
              : "false"
          }
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>Library not found!</p>
            <hr />
            <p className="mb-0">
              {(loggedInUser.Role.Name == "Master Admin" ||
                loggedInUser.Role.Name == "Organization Admin") && (
                <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                  Back to Libraries
                </Link>
              )}
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  if (loading || !resource || !resourceCategories) {
    return (
      <Fragment>
        <Breadcrumb
          specialParent={getParentBreadcrumb()}
          title="Edit Resource"
        />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb
          specialParent={getParentBreadcrumb()}
          title="Edit Resource"
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to={`${process.env.PUBLIC_URL}/resources`}>
                Back to Resources
              </Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Breadcrumb specialParent={getParentBreadcrumb()} title="Edit Resource" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {resource.MasterResource && (
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <p className="txt-primary">
                          Sync With Master Resource{" "}
                          <i
                            className={
                              syncLoading
                                ? "fa fa-refresh fa-spin ml-3"
                                : "fa fa-refresh ml-3"
                            }
                            title="Sync"
                            aria-hidden="true"
                            style={{ cursor: "pointer", fontSize: 20 }}
                            onClick={syncWithMasterResource}
                          ></i>
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Name</label>
                      <input
                        className="form-control"
                        name="Name"
                        type="text"
                        defaultValue={resource.Name}
                        placeholder="Name"
                        ref={register({ required: true })}
                      />
                      <span>{errors.Name && "Name is required"}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Code</label>
                      <input
                        className="form-control"
                        name="Code"
                        type="text"
                        defaultValue={resource.Code}
                        placeholder="Code"
                        ref={register({ required: true })}
                      />
                      <span>{errors.Code && "Code is required"}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Category</label>
                      <select
                        className="form-control digits"
                        name="ResourceCategories"
                        ref={register({})}
                        defaultValue={
                          resource.ResourceCategories
                            ? resource.ResourceCategories._id
                            : "select"
                        }
                      >
                        <option value="select" disabled>
                          Select
                        </option>
                        {resourceCategories.map((v, i) => {
                          return (
                            <option value={v._id} key={i}>
                              {v.Name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Description</label>
                      <textarea
                        className="form-control"
                        name="Description"
                        placeholder="Description"
                        defaultValue={resource.Description}
                        ref={register({ required: false })}
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {errors.Description && "Description is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Meta</label>
                      <textarea
                        className="form-control"
                        name="Meta"
                        placeholder="Meta"
                        defaultValue={resource.Meta}
                        ref={register({ required: true })}
                        rows="10"
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {errors.Meta && "Meta is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>

                  {error && (
                    <div
                      className="dark alert alert-danger fade show"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <button
                    className="btn btn-primary"
                    disabled={editing}
                    type="submit"
                  >
                    {editing ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditResource;
