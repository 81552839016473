import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";

// Components
import StatisticsAnalytics from "../dashboardenhanced/statistics-analytics";
import ResourceAccess from "../dashboardenhanced/resource-access";
import PdfDownloads from "../dashboardenhanced/pdf-downloads";
import LoginStatistics from "../dashboardenhanced/statistics-login";
import TopDownload from "../dashboardenhanced/top-downloads";
import TopResources from "../dashboardenhanced/top-resources";

// API's
import { API_BASE_TEST } from "../../../constant/api";
import moment from "moment";

class Default extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      totalUsersV2: {}, // total users
      loading: false, // loading state
      selectedOption: null,
      libraries: [],
      page: 1,
      pageSize: 20,
      hasMore: true,
      loadingMore: false,
      searchValue: "", // State to store the search input value
    };

    this.abortController = null; // Initialize AbortController
  }

  handleSelectChange = (selectedOption) => {
    this.setState({ selectedOption, loading: true }, () => {
      // Call getTotalUsers with the selected library ID and show loading
      this.getTotalUsers(selectedOption.value);
    });
  };

  handleInputChange = (inputValue) => {
    this.setState({ searchValue: inputValue, page: 1, hasMore: true }, () => {
      this.getNewLibraries(1); // Fetch new data based on the search input
    });
  };
  getTotalUsers = (libraryId = "" || JSON.parse(localStorage.getItem("user"))?.Library?._id) => {
    // Check if a libraryId is provided. If not, return early and don't proceed with the API call.
    if (!libraryId) {
      console.warn("Library ID not provided, skipping API call.");
      return; // Exit the function if no libraryId is provided.
    }

    // Construct the base URL
    let url = new URL(`${API_BASE_TEST}v2/dashboard/dashboard-count`);

    // Add the libraryId as a query parameter
    url.searchParams.append("libraryId", libraryId || JSON.parse(localStorage.getItem("user"))?.Library?._id);
    // url.searchParams.append("fromDate", moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD")); // 7 days ago from today
    url.searchParams.append("fromDate", "2024-01-01"); // 7 days ago from today
    // url.searchParams.append("toDate", moment().endOf("day").format("YYYY-MM-DD")); // End of today
    url.searchParams.append("toDate", "2024-08-07"); // End of today

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          this.setState({
            totalUsersV2: res.data, // Store the fetched data in the state
            loading: false, // Data received, stop loading
          });
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          toast.error("Failed to fetch total users!", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ loading: false });
        }
      });
  };

  // getTotalUsers = (libraryId = "") => {
  //   // Construct the base URL
  //   let url = new URL(`${API_BASE_TEST}v2/dashboard/dashboard-count`);

  //   // If a libraryId is provided, add it as a query parameter
  //   if (libraryId) {
  //     url.searchParams.append("libraryId", libraryId);
  //   }

  //   return fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res) {
  //         this.setState({
  //           totalUsersV2: res.data, // Store the fetched data in the state
  //           loading: false, // Data received, stop loading
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.name !== "AbortError") {
  //         toast.error("Failed to fetch total users!", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         this.setState({ loading: false });
  //       }
  //     });
  // };

  // getNewLibraries = (page = 1) => {
  //   const { pageSize, searchValue } = this.state;

  //   // Cancel any ongoing request before making a new one
  //   if (this.abortController) {
  //     this.abortController.abort();
  //   }

  //   // Create a new AbortController instance for the new request
  //   this.abortController = new AbortController();

  //   var url = new URL(`${API_BASE_TEST}v2/common/library/list`);
  //   url.searchParams.append("page", page);
  //   url.searchParams.append("page_size", pageSize);

  //   if (searchValue) {
  //     url.searchParams.append("search", searchValue);
  //   }

  //   return fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //     signal: this.abortController.signal, // Pass the AbortController signal
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res?.status) {
  //         const newLibraries = res.data.libraries.map((v) => ({
  //           value: v._id,
  //           label: v.UniversityName,
  //         }));

  //         this.setState((prevState) => ({
  //           libraries:
  //             page === 1
  //               ? newLibraries
  //               : [...prevState.libraries, ...newLibraries],
  //           page: page + 1,
  //           hasMore: newLibraries.length === pageSize,
  //           loadingMore: false,
  //         }));
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.name !== "AbortError") {
  //         toast.error("Failed to fetch libraries!", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         this.setState({ loadingMore: false });
  //       }
  //     });
  // };

  getNewLibraries = (page = 1) => {
    const { pageSize, searchValue } = this.state;

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();

    var url = new URL(`${API_BASE_TEST}v2/common/library/list`);
    url.searchParams.append("page", page);
    url.searchParams.append("page_size", pageSize);

    if (searchValue) {
      url.searchParams.append("search", searchValue);
    }

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      signal: this.abortController.signal,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.status) {
          const newLibraries = res.data.libraries.map((v) => ({
            value: v._id,
            label: v.UniversityName,
          }));

          this.setState((prevState) => {
            let selectedOption = prevState.selectedOption;

            // Automatically select the first library if none is selected
            if (!selectedOption && newLibraries.length > 0) {
              selectedOption = newLibraries[0];
              this.getTotalUsers(newLibraries[0].value); // Fetch data for the first library
            }

            return {
              libraries: page === 1 ? newLibraries : [...prevState.libraries, ...newLibraries],
              selectedOption,  // Set the selectedOption
              page: page + 1,
              hasMore: newLibraries.length === pageSize,
              loadingMore: false,
            };
          });
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          toast.error("Failed to fetch libraries!", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ loadingMore: false });
        }
      });
  };


  handleMenuScrollToBottom = () => {
    const { hasMore, loadingMore } = this.state;

    if (hasMore && !loadingMore) {
      this.setState({ loadingMore: true }, () => {
        this.getNewLibraries(this.state.page);
      });
    }
  };

  componentDidMount() {
    this.getTotalUsers(); // Call without library ID by default

    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    if (
      JSON.parse(localStorage.getItem("user")).Role.Name === "Library Admin"
    ) {
      this.setState({
        filterlibrary: JSON.parse(localStorage.getItem("user")).Library._id,
        filteredlibrary: JSON.parse(localStorage.getItem("user")).Library._id,
      });
    } else {
      this.getNewLibraries(); // Fetch the libraries
    }
  }

  render() {
    const { selectedOption, libraries, loading, loadingMore } = this.state;

    return (
      <Fragment>
        <Breadcrumb parent="false" title="Dashboard" />
        <div className="container-fluid">
          {JSON.parse(localStorage.getItem("user"))?.Role?.Name === "Library Admin" ? null : (<Row className="mb-4">
            <Col md={12}>
              <Select
                value={selectedOption}
                onChange={this.handleSelectChange}
                options={libraries}
                placeholder="Select Library"
                onMenuScrollToBottom={this.handleMenuScrollToBottom}
                onInputChange={this.handleInputChange} // Handle search input change
                isLoading={loadingMore}
                isDisabled={loading}
              />
            </Col>
          </Row>)}

          <Row className="mb-4">
            <Col md={12}>
              <StatisticsAnalytics
                totalUsersV2={this.state.totalUsersV2}
                loading={this.state.loading}
              />
            </Col>
            <Col md={12} className="mt-4">
              <LoginStatistics
                libraryId={selectedOption?.value || JSON.parse(localStorage.getItem("user"))?.Library?._id}
                loading={loading}
              />
            </Col>
            <Col md={6} className="mt-4">
              <ResourceAccess
                libraryId={selectedOption?.value || JSON.parse(localStorage.getItem("user"))?.Library?._id}
                loading={loading}
              />
            </Col>
            <Col md={6} className="mt-4">
              <PdfDownloads
                libraryId={selectedOption?.value || JSON.parse(localStorage.getItem("user"))?.Library?._id}
                loading={loading}
              />
            </Col>
            <Col md={6} className="mt-4">
              <TopResources
                libraryId={selectedOption?.value || JSON.parse(localStorage.getItem("user"))?.Library?._id}
                loading={loading}
              />
            </Col>
            <Col md={6} className="mt-4">
              <TopDownload
                libraryId={selectedOption?.value || JSON.parse(localStorage.getItem("user"))?.Library?._id}
                loading={loading}
              />
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default Default;
