import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class MyAreaChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          type: "area",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: "zoom",
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          strokeDashArray: 4,
        },
        dataLabels: {
          enabled: true,
          offsetY: -10,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
            fontWeight: "bold",
            fontFamily: "Nunito", // Apply Nunito font to data labels
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
          colors: ["#0f84a9"],
        },
        xaxis: {
          categories: [], // Will be dynamically updated
          title: {
            text: "", // Will be dynamically updated with xaxisTitle prop
            style: {
              color: "#000",
              fontFamily: "Nunito", // Apply Nunito font to x-axis title
              fontSize: "14px",
            },
          },
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              fontFamily: "Nunito", // Apply Nunito font to x-axis labels
            },
          },
        },
        yaxis: {
          title: {
            text: "", // Will be dynamically updated with yaxisTitle prop
            style: {
              color: "#000",
              fontFamily: "Nunito", // Apply Nunito font to y-axis title
              fontSize: "14px",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              fontFamily: "Nunito", // Apply Nunito font to y-axis labels
            },
            offsetX: 10,
            offsetY: 5,
          },
          min: 0,
          max: undefined,
          tickAmount: 8,
        },
        tooltip: {
          x: {
            formatter: (val, opts) =>
              `Date: ${opts.w.globals.categoryLabels[opts.dataPointIndex]}`,
          },
          y: {
            formatter: (val) => `${val}`,
          },
          marker: {
            show: false,
          },
          style: {
            fontFamily: "Nunito", // Apply Nunito font to tooltips
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        markers: {
          size: 6,
          colors: ["#0f84a9"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 9,
          },
        },
      },
      series: [
        {
          name: "Values",
          data: [], // Will be dynamically updated
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.xaxisTitle !== this.props.xaxisTitle ||
      prevProps.yaxisTitle !== this.props.yaxisTitle
    ) {
      // Extract the categories (dates) and values (counts)
      const categories = this.props.chartData?.map((item) =>
        item.date.split("-").reverse().join("/")
      );
      const data = this.props.chartData?.map((item) => item.count);

      // Update the state with the extracted data and new axis titles
      this.setState({
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: categories,
            title: {
              ...this.state.options.xaxis.title,
              text: this.props.xaxisTitle, // Update x-axis title
            },
          },
          yaxis: {
            ...this.state.options.yaxis,
            title: {
              ...this.state.options.yaxis.title,
              text: this.props.yaxisTitle, // Update y-axis title
            },
          },
        },
        series: [
          {
            name: "",
            data: data,
          },
        ],
      });
    }
  }

  componentDidMount() {
    const { chartData, xaxisTitle, yaxisTitle } = this.props;

    // Extract the categories (dates) and values (counts)
    const categories = chartData?.map((item) =>
      item.date.split("-").reverse().join("/")
    );
    const data = chartData?.map((item) => item.count);

    // Update the state with the extracted data and initial axis titles
    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: categories,
          title: {
            ...prevState.options.xaxis.title,
            text: xaxisTitle, // Set initial x-axis title
          },
        },
        yaxis: {
          ...prevState.options.yaxis,
          title: {
            ...prevState.options.yaxis.title,
            text: yaxisTitle, // Set initial y-axis title
          },
        },
      },
      series: [
        {
          name: yaxisTitle,
          data: data,
        },
      ],
    }));
  }

  getChartWidth() {
    const { chartData } = this.props;
    const areaWidth = 30;
    const categoriesCount = chartData ? chartData.length : 0;
    return Math.max(categoriesCount * areaWidth, 1200); // Minimum width of 1100px
  }

  render() {
    const chartWidth = this.getChartWidth();
    return (
      <div style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}>
        <div style={{ width: `${chartWidth}px` }}>
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="area"
            height={358}
          />
        </div>
      </div>
    );
  }
}

export default MyAreaChart;
