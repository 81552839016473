import React, { Component } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import { ReactComponent as ZoomIcon } from "../../assets/images/common/clip-modal-icon.svg";
import MyBarChart from "../dashboard/charts/barChart";

const Title = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  color: #0e1726;
`;

const DateSelected = styled.div`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  color: #0e1726;
`;

class PdfReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showChildModal: false, // State to control child modal visibility
      chartLoader: true,
      chartSeries: [],
      chartLabels: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pdfDownloadsData !== this.props.pdfDownloadsData &&
      this.props.pdfDownloadsData?.data
    ) {
      const { chartLabels, chartSeries } = this.processResourceData(
        this.props.pdfDownloadsData.data
      );
      console.log(chartLabels, chartSeries, "DFFFFF");
      this.setState({
        chartLabels,
        chartSeries,
        chartLoader: false, // Stop loader when data is available
      });
    }
  }

  handleZoomClick = () => {
    this.setState({ showChildModal: true });
  };

  processResourceData(resourceData) {
    const chartLabels = resourceData?.pdf_downloads?.map(
      (item) => item?.resourceName
    );
    const chartSeries = resourceData?.pdf_downloads?.map((item) => item?.count);
    return { chartLabels, chartSeries };
  }

  render() {
    const { showChildModal, chartLoader } = this.state;
    const { pdfDownloadsData, loading, fullscreen, fromDate, toDate } =
      this.props; // Add fullscreen prop

    return (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          padding: "10px 20px",
          boxShadow: "1px 5px 24px 0 rgba(68, 102, 242, 0.05)",
          // height: "470px",
        }}
      >
        <Row>
          <Col md={12} className="d-flex justify-content-between">
            <div>
              <Title>PDF Reports</Title>
              <DateSelected>
              Report : {moment(fromDate)?.format("MMM-DD, YYYY")} to{" "}
                {moment(toDate)?.format("MMM-DD, YYYY")}
              </DateSelected>
            </div>

            <div className="d-flex justify-content-end">
              {!fullscreen && ( // Conditionally render ZoomIcon
                <ZoomIcon
                  onClick={this.handleZoomClick} // Add onClick to ZoomIcon
                  style={{
                    height: "20px",
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                />
              )}
            </div>
          </Col>

          {!loading ? (
            <Col md={12}>
              <MyBarChart
                labels={this.state.chartLabels}
                series={this.state.chartSeries}
                chartData={pdfDownloadsData?.data?.pdf_downloads}
                chartLoader={chartLoader}
                xaxisTitle={'PDF Resource Names'}
                yaxisTitle={'PDF Utilization Count'}
                chartColor={'#FF993B'}
              />
            </Col>
          ) : (
            <Col xs={12} className="px-2">
              <div
                className="loader-box"
                style={{ flex: 1, justifyContent: "center" }}
              >
                <div className="loader">
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
              </div>
            </Col>
          )}
        </Row>

        {/* Child Modal for Fullscreen Resource Access */}
        <Modal
          show={showChildModal}
          onHide={() => this.setState({ showChildModal: false })}
          size="lg"
        >
          <Modal.Body>
            {/* <PdfReports {...this.props} fullscreen={true} />{" "} */}
            {/* Pass fullscreen=true */}
            <div>
              <Title>PDF Reports</Title>
              <DateSelected>
                {moment(fromDate)?.format("MMM-DD, YYYY")} to{" "}
                {moment(toDate)?.format("MMM-DD, YYYY")}
              </DateSelected>
            </div>
            <MyBarChart
                labels={this.state.chartLabels}
                series={this.state.chartSeries}
                chartData={pdfDownloadsData?.data?.pdf_downloads}
                chartLoader={chartLoader}
                xaxisTitle={'PDF Resource Names'}
                yaxisTitle={'PDF Utilization Count'}
                chartColor={'#FF993B'}
              />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showChildModal: false })}
              className="mx-3"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default PdfReports;
