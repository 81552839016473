import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import ReportStatistics from "../../common/dashboard/statistics-reports";
import moment from "moment";
import styled from "styled-components";

// Function to get the first date of the current month and today's date using Moment.js
const getCurrentDateRange = () => {
  const firstDayOfMonth = moment().subtract(7, "days").startOf("day").format("MMM-DD, YYYY");
  const today = moment().endOf("day").format("MMM-DD, YYYY");
  return `${firstDayOfMonth} to ${today}`;
};
const Title = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  text-align: left;
  color: #0e1726;
`;

const Totalcount = styled.div`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.1px;
  text-align: left;
  font-color: #555555;
  margin-bottom: 20px;
`;

class StatisticsAnalytics extends Component {
  render() {
    const { totalUsersV2 } = this.props;
    const { loading } = this.props;
    const data = [
      {
        title: "Active Users",
        color1: "#0021E0",
        color2: "#26D0CE",
        percentage: 85,
        count: totalUsersV2?.active_user_count,
      },
      {
        title: "Logins",
        color1: "#E69700",
        color2: "#FFB11B",
        percentage: 85,
        count: totalUsersV2?.login_this_month_count,
      },
      {
        title: "Resource Access",
        color1: "#FF00CC",
        color2: "#8B3399",
        percentage: 85,
        count: totalUsersV2?.resource_access_count,
      },
      // {
      //   title: "Pdf Downloads",
      //   color1: "#00D3B9",
      //   color2: "#047DC2",
      //   percentage: 85,
      //   count: totalUsersV2?.pdf_download_count,
      // },
      {
        title: "Total users",
        color1: "#592ACF",
        color2: "#8900FF",
        percentage: 85,
        count: totalUsersV2?.total_users_count,
      },
      {
        title: "Web users",
        color1: "#00B45A",
        color2: "#0BFF84",
        percentage: 85,
        count: totalUsersV2?.total_web_users_count,
      },
      {
        title: "Mobile users",
        color1: "#FF416C",
        color2: "#FF4B2B",
        percentage: 85,
        count: totalUsersV2?.total_mobile_users_count,
      },
      // {
      //   title: "Total resources",
      //   color1: "#FC4A1A",
      //   color2: "#F7B733",
      //   percentage: 85,
      //   count: totalUsersV2?.total_resources_count,
      // },
    ];

    return (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          padding: "10px 20px",
          boxShadow: "1px 5px 24px 0 rgba(68, 102, 242, 0.05)",
          // height: "470px",
        }}
      >
        <Row className='d-flex justify-content-center align-items-center'>
          <Col md={12}>
            <Title> Statistics </Title>
            <Totalcount> {getCurrentDateRange()} </Totalcount>
            {/* <Totalcount> Jan-01, 2024 to Sept-07, 2024 </Totalcount> */}
          </Col>
          {!loading ? (
            data.map((item, index) => (
              <Col md={2} sm={6} xs={12} key={index} className="px-2">
                <ReportStatistics
                  title={item.title}
                  color1={item.color1}
                  color2={item.color2}
                  percentage={item.percentage}
                  count={item.count}
                  gradientId={`gradient-${index}`}
                />
              </Col>
            ))
          ) : (
            <Col xs={12} className="px-2">
              <div className="container-fluid">
                <div
                  className="loader-box"
                  style={{ flex: 1, justifyContent: "center" }}
                >
                  <div className="loader">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default StatisticsAnalytics;
