import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import logo from "../../../assets/images/endless-logo.png";
import logo_compact from "../../../assets/images/logo/compact-logo.png";

import UserPanel from "./userPanel";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
import configDB from "../../../data/customizer/config";
import {
  Home,
  Book,
  Database,
  Users,
  User,
  Lock,
  LifeBuoy,
  Package,
  Square,
  Octagon,
  Award,
  BarChart2,
  Activity,
  Info,
  Mail,
} from "react-feather";

const Sidebar = (props) => {
  var MENUITEMS = [];
  const user = JSON.parse(localStorage.getItem("user"));
  const nvUsers = localStorage.getItem("nvUsers");
  const userRole = user ? user.Role : null;
  if (userRole && userRole.IsAdmin) {
    MENUITEMS = [
      {
        title: "Admin Dashboard",
        icon: Home,
        type: "link",
        path: "/dashboard",
      },
      {
        title: "Clients",
        icon: Database,
        type: "link",
        path: "/organizations",
      },
      {
        title: "Admin Users",
        icon: Users,
        type: "link",
        path: "/users",
      },
      {
        title: "Resource Configurations",
        icon: Square,
        type: "link",
        path: "/master-resources",
      },
      {
        title: "Client's Libraries",
        icon: Book,
        type: "link",
        path: "/libraries",
      },
      {
        title: "Client Licenses",
        icon: Award,
        type: "link",
        path: "/licenses",
      },
      {
        title: "User Activity Reports",
        icon: BarChart2,
        type: "link",
        // path: "/reports",
        path: "/user-activity-report",
      },
    ];
  }
  if (userRole && userRole.Name == "Organization Admin") {
    MENUITEMS = [
      {
        title: "Dashboard",
        icon: Home,
        type: "link",
        path: "/dashboard",
      },
      // {
      //   title: "Library Admins",
      //   icon: Users,
      //   type: "link",
      //   path: "/users",
      // },
      // {
      //   title: "Libraries",
      //   icon: Book,
      //   type: "link",
      //   path: "/libraries",
      // },
      {
        title: "User Activity Reports",
        icon: BarChart2,
        type: "link",
        // path: "/reports",
        path: "/user-activity-report",
      },
    ];
  }
  if (userRole && userRole.Name == "Library Admin") {
    if (user.Library && user.Library._id) {
      MENUITEMS = [
        {
          title: "Dashboard",
          icon: Home,
          type: "link",
          path: "/dashboard",
        },
        {
          title: "Info",
          icon: Info,
          type: "link",
          path: `/library/view/${user.Library._id}`,
        },
        {
          title: "Groups",
          icon: Octagon,
          type: "link",
          path: `/library/${user.Library._id}/groups`,
        },
        {
          title: "Users",
          icon: Users,
          type: "link",
          path: `/library/${user.Library._id}/users`,
        },
        //sign up code
        {
          title: "Registration Approval",
          icon: Users,
          type: "link",
          path: `/library/${user.Library._id}/register-users`,
        },
        //end
        // {
        //   title: "Resource Category",
        //   icon: LifeBuoy,
        //   type: "link",
        //   path: `/library/${user.Library._id}/resource-categories`,
        // },
        {
          title: "Resources",
          icon: Package,
          type: "link",
          path: `/library/${user.Library._id}/resources`,
        },

        {
          title: "Authentication",
          icon: Lock,
          type: "link",
          path: `/library/${user.Library._id}/auths`,
        },
        {
          title: "User Activity Reports",
          icon: BarChart2,
          type: "link",
          // path: "/reports",
          path: "/user-activity-report",
        },
        {
          title: "Custom Emails",
          icon: Mail,
          type: "link",
          path: `/library/${user.Library._id}/custom-emails`,
        },
      ];
    }
  }
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const wrapper = configDB.data.settings.sidebar.wrapper;
  const layout = useSelector((content) => content.Customizer.layout);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    var currentUrl = window.location.pathname;

    // eslint-disable-next-line
    mainmenu.filter((items) => {
      if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      // eslint-disable-next-line
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        // eslint-disable-next-line
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) setNavActive(subSubItems);
        });
      });
    });

    setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      const menuWidth = elmnt.offsetWidth;
      // setMenuWidth(menuWidth)
      if (menuWidth > window.innerWidth) {
        setHideRightArrow(false);
        setHideLeftArrowRTL(false);
      } else {
        setHideRightArrow(true);
        setHideLeftArrowRTL(true);
      }
    }, 500);

    return () => {
      // eslint-disable-next-line
      window.addEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 310);
  };

  const setNavActive = (item) => {
    // eslint-disable-next-line
    MENUITEMS.filter((menuItem) => {
      // eslint-disable-next-line
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        // eslint-disable-next-line
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      MENUITEMS.forEach((a) => {
        if (MENUITEMS.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById("myDIV");
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  return (
    <Fragment>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper compactLogo">
            <Link to="/dashboard">
              <img className="blur-up lazyloaded" src={logo_compact} alt="" />
              <img
                className="blur-up lazyloaded"
                src={logo}
                alt=""
                style={{ width: "95%" }}
              />
            </Link>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          <UserPanel />
          <ul
            className="sidebar-menu"
            id="myDIV"
            style={
              wrapper === "horizontal_sidebar"
                ? layout === "rtl"
                  ? { marginRight: margin + "px" }
                  : { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <li
              className={`left-arrow ${layout === "rtl"
                ? hideLeftArrowRTL
                  ? "d-none"
                  : ""
                : hideLeftArrow
                  ? "d-none"
                  : ""
                }`}
              onClick={
                wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToLeftRTL
                  : scrollToLeft
              }
            >
              <i className="fa fa-angle-left"></i>
            </li>
            {MENUITEMS.map((menuItem, i) => (
              <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                {menuItem.sidebartitle ? (
                  <div className="sidebar-title">{menuItem.sidebartitle}</div>
                ) : (
                  ""
                )}
                {menuItem.type === "sub" ? (
                  <a
                    className="sidebar-header"
                    href="#javascript"
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon />
                    <span>{props.t(menuItem.title)}</span>
                    <i className="fa fa-angle-right pull-right"></i>
                  </a>
                ) : (
                  ""
                )}
                {menuItem.type === "link" ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                    className={`sidebar-header ${menuItem.active ? "active" : ""
                      }`}
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon />
                    <span>
                      {props.t(menuItem.title)}
                      {menuItem.title === "Register Users   " && nvUsers > 0 ? (
                        <div
                          style={{
                            backgroundColor: "red",
                            display: "inline-block",
                            height: "20px",
                            width: "20px",
                            textAlign: "center",
                            position: "relative",
                            top: "-5px",
                            borderRadius: "50%",
                          }}
                        >
                          {nvUsers + " "}
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                    {menuItem.children ? (
                      <i className="fa fa-angle-right pull-right"></i>
                    ) : (
                      ""
                    )}
                  </Link>
                ) : (
                  ""
                )}
                {menuItem.children ? (
                  <ul
                    className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""
                      }`}
                    style={
                      menuItem.active
                        ? { opacity: 1, transition: "opacity 500ms ease-in" }
                        : {}
                    }
                  >
                    {menuItem.children.map((childrenItem, index) => (
                      <li
                        key={index}
                        className={
                          childrenItem.children
                            ? childrenItem.active
                              ? "active"
                              : ""
                            : ""
                        }
                      >
                        {childrenItem.type === "sub" ? (
                          <a
                            href="#javascript"
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <i className="fa fa-circle"></i>
                            {props.t(childrenItem.title)}{" "}
                            <i className="fa fa-angle-right pull-right"></i>
                          </a>
                        ) : (
                          ""
                        )}

                        {childrenItem.type === "link" ? (
                          <Link
                            to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                            className={childrenItem.active ? "active" : ""}
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <i className="fa fa-circle"></i>
                            {props.t(childrenItem.title)}{" "}
                          </Link>
                        ) : (
                          ""
                        )}
                        {childrenItem.children ? (
                          <ul
                            className={`sidebar-submenu ${childrenItem.active ? "menu-open" : "active"
                              }`}
                          >
                            {childrenItem.children.map(
                              (childrenSubItem, key) => (
                                <li
                                  className={
                                    childrenSubItem.active ? "active" : ""
                                  }
                                  key={key}
                                >
                                  {childrenSubItem.type === "link" ? (
                                    <Link
                                      to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                      className={
                                        childrenSubItem.active ? "active" : ""
                                      }
                                      onClick={() =>
                                        toggletNavActive(childrenSubItem)
                                      }
                                    >
                                      <i className="fa fa-circle"></i>
                                      {props.t(childrenSubItem.title)}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
            <li
              className={`right-arrow ${layout === "rtl"
                ? hideRightArrowRTL
                  ? "d-none"
                  : ""
                : hideRightArrow
                  ? "d-none"
                  : ""
                }`}
              onClick={
                wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToRightRTL
                  : scrollToRight
              }
            >
              <i className="fa fa-angle-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default translate(Sidebar);
