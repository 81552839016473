import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import NoResultImg from "../../../assets/images/common/noresults.jpg";

class MyDonutChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          type: "donut",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              console.log(config, chartContext);
            },
          },
        },
        labels: this.props.labels || [
          "Option A",
          "Option B",
          "Option C",
          "Option D",
        ],
        colors: [ '#26D0CE', '#E69700', '#FFB11B', '#FF00CC', '#8B3399', '#00D3B9', '#047DC2', '#592ACF', '#8900FF', '#00B45A', '#0BFF84', '#FF416C', '#FF4B2B', '#FC4A1A', '#F7B733', '#17BC07', '#37FC17', '#7CC89C', '#F64814', '#7E843C', '#041227', '#1DBF10', '#438454', '#AD511D', '#9ADD49', '#258304', '#1D3615', '#47AE10', '#3A6358', '#72C97B', '#CDB4FE', '#378B1B', '#680085', '#DD8EAA', '#0072D1', '#6F96D7', '#9883EC', '#5B86AC', '#AA0563', '#B4B704', '#71221F', '#70A5A4', '#73F748', '#24B1B0', '#F2B40C']
        , // Custom colors
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "left",
              },
            },
          },
        ],
        legend: {
          show: false,
          position: "left",
          labels: {
            colors: ["#000"],
            useSeriesColors: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Nunito",
          },
          background: {
            enabled: true,
            foreColor: "#000", // Text color inside labels
            padding: 5,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: "#ccc", // Border color for labels
            opacity: 0.9,
          },
          dropShadow: {
            enabled: false,
          },
          formatter: function (val, opts) {
            return val.toFixed(1) + "%";
          },
        },
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            // Custom HTML for tooltip
            return (
              '<div style="background-color: black; color: white; padding: 5px; border-radius: 4px; font-family: Nunito;">' +
              "<strong>" +
              w.globals.labels[seriesIndex] +
              "</strong>: " +
              series[seriesIndex] +
              "</div>"
            );
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "45%", // Make the hole larger
              labels: {
                show: false,
                total: {
                  show: true,
                  label: "Total",
                  formatter: () => {
                    return "100%";
                  },
                },
              },
            },
          },
        },
      },
      series: this.props.series || [48.8, 24.3, 14.6, 12.3], // Default series
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only update if labels or series change
    return (
      nextProps.labels !== this.props.labels ||
      nextProps.series !== this.props.series
    );
  }

  render() {
    const { chartLoader } = this.props;
    const series = this.props.series || this.state.series;
    // Check if the series is empty or if all values in the series are zero
    const isEmptySeries =
      !series || series.length === 0 || series.every((val) => val === 0);

    if (chartLoader) {
      return (
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      );
    }

    return isEmptySeries ? (
      <div style={{ textAlign: "center", marginTop: "20px", fontFamily:"Nunito" }}>
        No record found <br/>
        <img src={NoResultImg} alt="No record found" style={{width: "300px", height: "300px"}} />

      </div>
    ) : (
      <div style={{ pointerEvents: "auto" }}>
        <ReactApexChart
          options={this.state.options || this.props.options}
          series={series}
          type="donut"
          height={420}
        />
      </div>
    );
  }
}

export default MyDonutChart;
