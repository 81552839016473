export const API_BASE = "https://testadmin.remotlog.com/";
// export const API_BASE = "http://localhost:9017/";
// export const API_BASE = "http://20.204.168.47:9017/";
// export const API_BASE_TEST = "http://localhost:9017/"
export const API_BASE_TEST = "https://testadmin.remotlog.com/"
// export const API_BASE_TEST = "http://3.144.118.25:9017/" // "https://abjs2yyhtk.us-east-2.awsapprunner.com/";
// export const API_BASE_TEST = "http://20.204.168.47:9017/"
// export const API_BASE = "http://localhost:3005/";
export const FILE_BASE = "https://testadmin.remotlog.com/";
// export const FILE_BASE = "http://20.204.168.47:9017/";
// export const FILE_BASE = "http://localhost:9017/";
// export const API_BASE = "http://vmi317167.contaboserver.net:3005/";
// export const FILE_BASE =
//   "http://localhost:3001/";
//   "http://localhost:3001/";
