import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import NoResultImg from "../../../assets/images/common/noresults.jpg";

class MyBarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          type: "bar",
          height: 350,
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: "zoom",
          },
        },
        theme: {
          fontFamily: "Nunito", // Apply Nunito font globally
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "60%", // Increase spacing by decreasing column width
            endingShape: "rounded",
            borderRadius: 8,
            dataLabels: {
              position: "top",
            },
            barHeight: "45px",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            if (val >= 1000000) {
              return (val / 1000000).toFixed(1) + 'M'; // For millions
            } else if (val >= 1000) {
              return (val / 1000).toFixed(1) + 'K'; // For thousands
            } else {
              return val; // For values less than 1000
            }
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#456AEA"],
            fontFamily: "Nunito",
          },
          orientation: "horizontal",
        },
        
        xaxis: {
          title: {
            text: "",
            style: {
              color: "#000",
              fontFamily: "Nunito",
              fontSize: "14px",
            },
          },
          categories: [],
          tickPlacement: "on",
          labels: {
            rotate: -50, // Set this to 0 for horizontal text
            style: {
              fontFamily: "Nunito",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis", // Truncate long labels
              maxHeight: 70, // Adjust as per requirement
            },
          },
        },
        yaxis: {
          title: {
            text: "",
            style: {
              color: "#000",
              fontFamily: "Nunito",
              fontSize: "14px",
            },
          },
          labels: {
            style: {
              colors: "#000",
              fontFamily: "Nunito",
            },
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        fill: {
          opacity: 1,
          colors: ["#456AEA"], // Default bar color
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
            style: {
              fontFamily: "Nunito",
            },
          },
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    };
  }

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.xaxisTitle !== this.props.xaxisTitle ||
      prevProps.yaxisTitle !== this.props.yaxisTitle ||
      prevProps.chartColor !== this.props.chartColor
    ) {
      this.updateChartData();
    }
  }

  updateChartData() {
    const { chartData, xaxisTitle, yaxisTitle, chartColor } = this.props;
    if (chartData && chartData.length > 0) {
      const categories = chartData.map((item) => item.resourceName);
      const data = chartData.map((item) => item.count);

      this.setState({
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: categories,
            title: {
              ...this.state.options.xaxis.title,
              text: xaxisTitle || "Resources Used",
            },
          },
          yaxis: {
            title: {
              text: yaxisTitle || "Utilization Count",
              style: {
                color: "#000",
                fontFamily: "Nunito",
                fontSize: "14px",
              },
            },
            labels: {
              style: {
                fontFamily: "Nunito", // Make sure the font is applied here
                colors: chartColor || "#456AEA",
                fontSize: "12px", // Set the font size explicitly
              },
            },
          },          
          fill: {
            ...this.state.options.fill,
            colors: [chartColor || "#456AEA"],
          },
          dataLabels: {
            ...this.state.options.dataLabels,
            style: {
              colors: [chartColor || "#456AEA"],
              fontFamily: "Nunito",
            },
          },
        },
        series: [
          {
            name: "",
            data: data,
          },
        ],
      });
    }
  }

  getChartWidth() {
    const { chartData } = this.props;
    const barWidth = 10;
    const categoriesCount = chartData ? chartData.length : 0;
    return Math.max(categoriesCount * barWidth, 1100); // Minimum width of 1100px
  }

  render() {
    const chartWidth = this.getChartWidth();
    const { chartData } = this.props;
    return (
      <div style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}>
        {chartData && chartData.length > 0 ? (
          <div style={{ width: `${chartWidth}px` }}>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height={420}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "20px", fontFamily:"Nunito" }}>
             No record found <br/>
             <img src={NoResultImg} alt="No record found" style={{width: "300px", height: "300px"}} />
          </div>
        )}
      </div>
    );
  }
}

export default MyBarChart;
