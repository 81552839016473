import React, { Component } from "react";
import { Row, Col, Dropdown, Modal, Button } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { API_BASE_TEST } from "../../../constant/api";
import { ReactComponent as ZoomIcon } from "../../../assets/images/common/clip-modal-icon.svg";
import MyAreaChart from "../charts/areaChart";

const Title = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  color: #0e1726;
`;

const DateSelected = styled.div`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  color: #0e1726;
`;

const CustomDropdownToggle = styled(Dropdown.Toggle)`
  background-color: #f7f7f7 !important;
  border: 1px solid #d9d9d9 !important;
  color: #000 !important;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: #f7f7f7 !important;
    border: 1px solid #d9d9d9 !important;
  }
`;

class LoginStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.selectedOption || "One Week",
      fromDate: props.fromDate || moment().subtract(7, "days").startOf("day"),
      toDate: props.toDate || moment().endOf("day"),
      output: props.output || {
        startDate: moment()
          .subtract(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
        endDate: moment().endOf("day").format("YYYY-MM-DD"),
      },
      showModal: false,
      showChildModal: false,
      resourceData: null,
      chartLoader: true,
    };
  }

  componentDidMount() {
    this.fetchResourceAccessData();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevProps.libraryId !== this.props.libraryId ||
  //     prevState.output.startDate !== this.state.output.startDate ||
  //     prevState.output.endDate !== this.state.output.endDate
  //   ) {
  //     this.fetchResourceAccessData();
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    // Check if the props related to the selected date range have changed
    if (
      prevProps.selectedOption !== this.props.selectedOption ||
      prevProps.fromDate !== this.props.fromDate ||
      prevProps.toDate !== this.props.toDate ||
      prevProps.output !== this.props.output
    ) {
      this.setState({
        selectedOption: this.props.selectedOption,
        fromDate: this.props.fromDate,
        toDate: this.props.toDate,
        output: this.props.output,
      });
    }

    // Check if a fetchResourceAccessData call is needed
    if (
      prevProps.libraryId !== this.props.libraryId ||
      prevState.output?.startDate !== this.state.output?.startDate ||
      prevState.output?.endDate !== this.state.output?.endDate
    ) {
      this.fetchResourceAccessData();
    }
  }

  fetchResourceAccessData = () => {
    const { libraryId } = this.props;
    const { output } = this.state;

    // Check if libraryId is present, if not, exit early
    if (!libraryId) {
      console.warn("Library ID not provided, skipping API call.");
      return; // Exit the function without making the API call
    }
    this.setState({ chartLoader: true });

    let url = `${API_BASE_TEST}v2/dashboard/login-statistics?fromDate=${output.startDate}&toDate=${output.endDate}`;

    if (libraryId) {
      url += `&libraryId=${libraryId}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ resourceData: data?.data, chartLoader: false });
      })
      .catch((error) => {
        console.error("Error fetching resource access data:", error);
      });
  };

  handleSelectDateRange = (option, picker = null) => {
    let fromDate = moment();
    let toDate = moment();
    let output = { startDate: "", endDate: "" };

    switch (option) {
      case "One Week":
        fromDate = moment().subtract(7, "days");
        break;
      case "Last 15 Days":
        fromDate = moment().subtract(15, "days");
        break;
      case "Last 30 Days":
        fromDate = moment().subtract(30, "days");
        break;
      case "This Month":
        fromDate = moment().startOf("month");
        break;
      case "Custom Date":
        if (picker) {
          fromDate = picker.startDate;
          toDate = picker.endDate;
        } else {
          this.setState({ showModal: true, selectedOption: option });
          return;
        }
        break;
      default:
        break;
    }

    output.startDate = fromDate.format("YYYY-MM-DD");
    output.endDate = toDate.format("YYYY-MM-DD");

    this.setState({
      selectedOption: option,
      fromDate,
      toDate,
      output,
      showModal: false,
    });
  };

  handleZoomClick = () => {
    this.setState({ showChildModal: true });
  };

  render() {
    const {
      showModal,
      showChildModal,
      selectedOption,
      output,
      resourceData,
      chartLoader,
    } = this.state;
    const { loading, fullscreen } = this.props; // Add fullscreen prop

    return (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          padding: "10px 20px",
          boxShadow: "1px 5px 24px 0 rgba(68, 102, 242, 0.05)",
          height: "470px",
        }}
      >
        <Row>
          <Col md={12} className="d-flex justify-content-between">
            <div>
              <Title>Login Statistics</Title>
              <DateSelected>
                {moment(output?.startDate)?.format("MMM-DD, YYYY")} to{" "}
                {moment(output?.endDate)?.format("MMM-DD, YYYY")}
              </DateSelected>
            </div>

            <div className="d-flex justify-content-end">
              <Dropdown
                onSelect={(eventKey) => this.handleSelectDateRange(eventKey)}
                className={`${!fullscreen ? "mx-2" : ""}`}
              >
                <CustomDropdownToggle id="dropdown-basic">
                  {selectedOption}
                </CustomDropdownToggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="One Week">One Week</Dropdown.Item>
                  <Dropdown.Item eventKey="Last 15 Days">
                    Last 15 Days
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Last 30 Days">
                    Last 30 Days
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="This Month">
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Custom Date">
                    Custom Date
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {!fullscreen && ( // Conditionally render ZoomIcon
                <ZoomIcon
                  onClick={this.handleZoomClick} // Add onClick to ZoomIcon
                  style={{
                    height: "20px",
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                />
              )}
            </div>
          </Col>

          {!loading && !chartLoader ? (
            <Col md={12}>
              <MyAreaChart
                chartData={resourceData?.login_statistics}
                chartLoader={chartLoader}
                xaxisTitle={"Choosen Date"}
                yaxisTitle={"Login Count"}
              />
            </Col>
          ) : (
            <Col xs={12} className="px-2">
              <div
                className="loader-box"
                style={{ flex: 1, justifyContent: "center" }}
              >
                <div className="loader">
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
              </div>
            </Col>
          )}
        </Row>

        {/* Child Modal for Fullscreen Resource Access */}
        <Modal
          show={showChildModal}
          onHide={() => this.setState({ showChildModal: false })}
          size="lg"
        >
          <Modal.Body>
            <LoginStatistics
              {...this.props}
              fullscreen={true} // Pass fullscreen=true
              selectedOption={selectedOption} // Pass selectedOption
              fromDate={this.state.fromDate} // Pass fromDate
              toDate={this.state.toDate} // Pass toDate
              output={this.state.output} // Pass output
            />
            {/* Pass fullscreen=true */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showChildModal: false })}
              className="mx-3"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DateRangePicker
              initialSettings={{
                startDate: this.state.fromDate.toDate(),
                endDate: this.state.toDate.toDate(),
                maxDate: moment().toDate(),
              }}
              onApply={(event, picker) =>
                this.handleSelectDateRange("Custom Date", picker)
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                }}
              >
                Choose Custom Dates
              </div>
            </DateRangePicker>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default LoginStatistics;
