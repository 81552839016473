import React, { Component } from "react";
import { Row, Col, Dropdown, Modal, Button } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { API_BASE_TEST } from "../../../constant/api"; // Import your API base

// Import SVG as a React component
import { ReactComponent as ZoomIcon } from "../../../assets/images/common/clip-modal-icon.svg";
import MyDonutChart from "../charts/donutChart";
import MyBarChart from "../charts/barChart";
import MyPieChart from "../charts/pieChart";

const Title = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  text-align: left;
  color: #0e1726;
`;

const DateSelected = styled.div`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 24.55px;
  text-align: left;
  color: #0e1726;
`;

const Totalcount = styled.div`
  font-family: Nunito;
  color: #5d45db;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21.74px;
  text-align: left;
  margin-top: 5px;
`;

const TotalCountText = styled.div`
  font-family: Nunito;
  color: #0e1726;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.74px;
  text-align: left;
  margin-top: 5px;
  display: inline-block;
  margin-right: 5px;
`;

const CustomDropdownToggle = styled(Dropdown.Toggle)`
  background-color: #f7f7f7 !important;
  border: 1px solid #d9d9d9 !important;
  color: #000 !important;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  line-height: 19.1px;
  &:focus {
    box-shadow: none !important;
    background-color: #f7f7f7 !important;
    border: 1px solid #d9d9d9 !important;
  }
  &:hover {
    box-shadow: none !important;
    background-color: #f7f7f7 !important;
    border: 1px solid #d9d9d9 !important;
  }
`;

class ResourceAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.selectedOption || "One Week",
      selectedChart: props.selectedChart || "Donut",
      fromDate: props.output?.startDate
        ? moment(props.output.startDate)
        : moment().subtract(7, "days").startOf("day"),
      toDate: props.output?.endDate
        ? moment(props.output.endDate)
        : moment().endOf("day"),
      output: props.output || {
        type: "One Week",
        startDate: moment()
          .subtract(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
        endDate: moment().endOf("day").format("YYYY-MM-DD"),
      },
      resourceData: null,
      chartLoader: true,
      chartLabels: [],
      chartSeries: [],
    };
  }

  componentDidMount() {
    this.fetchResourceAccessData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.libraryId !== this.props.libraryId ||
      prevState.output.startDate !== this.state.output.startDate ||
      prevState.output.endDate !== this.state.output.endDate
    ) {
      this.fetchResourceAccessData();
    }
  }

  processResourceData(resourceData) {
    const chartLabels = resourceData?.resource_access?.map(
      (item) => item?.resourceName
    );
    const chartSeries = resourceData?.resource_access?.map(
      (item) => item?.count
    );

    return { chartLabels, chartSeries };
  }

  handleSelectDateRange = (option, picker = null) => {
    let fromDate = moment();
    let toDate = moment();
    let output = { type: option, startDate: "", endDate: "" };

    if (option === "One Week") {
      fromDate = moment().subtract(7, "days");
    } else if (option === "Last 15 Days") {
      fromDate = moment().subtract(15, "days");
    } else if (option === "Last 30 Days") {
      fromDate = moment().subtract(30, "days");
    } else if (option === "This Month") {
      fromDate = moment().startOf("month");
      toDate = moment();
    } else if (option === "Custom Date" && picker) {
      fromDate = picker.startDate;
      toDate = picker.endDate;
    } else if (option === "Custom Date") {
      this.setState({ showModal: true, selectedOption: option });
      return;
    }

    output.startDate = fromDate.format("YYYY-MM-DD");
    output.endDate = toDate.format("YYYY-MM-DD");

    this.setState({
      selectedOption: option,
      fromDate,
      toDate,
      output,
      showModal: false,
    });
  };

  handleSelectChart = (chart) => {
    this.setState({
      chartLoader: true,
      selectedChart: chart,
    });
    this.fetchResourceAccessData();
  };

  fetchResourceAccessData = () => {
    const { libraryId } = this.props;
    const { output } = this.state;

    // Check if libraryId is present, if not, exit early
    if (!libraryId) {
      console.warn("Library ID not provided, skipping API call.");
      return; // Exit the function without making the API call
    }

    this.setState({
      chartLoader: true,
    });

    let url = `${API_BASE_TEST}v2/dashboard/resource-access?fromDate=${moment(
      output.startDate
    ).format("YYYY-MM-DD")}&toDate=${moment(output.endDate).format(
      "YYYY-MM-DD"
    )}`;

    // Append the libraryId to the URL if it exists
    if (libraryId) {
      url += `&libraryId=${libraryId}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { chartLabels, chartSeries } = this.processResourceData(
          data?.data
        );

        this.setState({
          resourceData: data?.data,
          chartLoader: false,
          chartLabels,
          chartSeries,
        });
      })
      .catch((error) => {
        console.error("Error fetching resource access data:", error);
        this.setState({ chartLoader: false }); // Stop loader on error
      });
  };

  handleZoomClick = () => {
    this.setState({ showChildModal: true });
  };

  render() {
    const {
      showModal,
      showChildModal, // Modal visibility state
      selectedOption,
      selectedChart,
      output,
      resourceData,
      chartLoader,
    } = this.state;
    const { loading, fullscreen } = this.props; // Add fullscreen prop
    return (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          padding: "10px 20px",
          boxShadow: "1px 5px 24px 0 rgba(68, 102, 242, 0.05)",
          height: "470px",
        }}
      >
        <Row>
          <Col md={12} className="d-flex justify-content-between ">
            <div>
              <Title>Resource Access</Title>
              <DateSelected>
                {moment(output?.startDate)?.format("MMM-DD, YYYY")} to{" "}
                {moment(output?.endDate)?.format("MMM-DD, YYYY")}
              </DateSelected>
            </div>

            <div className="d-flex justify-content-end">
              <Dropdown
                onSelect={(eventKey) => this.handleSelectDateRange(eventKey)}
                className={`${!fullscreen ? "mx-2" : ""}`}
              >
                <CustomDropdownToggle id="dropdown-basic">
                  {selectedOption}
                </CustomDropdownToggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="One Week">One Week</Dropdown.Item>
                  <Dropdown.Item eventKey="Last 15 Days">
                    Last 15 Days
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Last 30 Days">
                    Last 30 Days
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="This Month">
                    This Month
                  </Dropdown.Item>

                  <Dropdown.Item eventKey="Custom Date">
                    Custom Date
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {!fullscreen && ( // Conditionally render ZoomIcon
                <ZoomIcon
                  onClick={this.handleZoomClick} // Add onClick to ZoomIcon
                  style={{
                    height: "20px",
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                />
              )}
            </div>
          </Col>
          {!loading && !chartLoader ? (
            <>
              {selectedChart === "Donut" ? (
                <Col
                  md={12}
                  className="justify-content-center px-5"
                  style={{ height: "360px" }}
                >
                  <MyDonutChart
                    labels={this.state.chartLabels}
                    series={this.state.chartSeries}
                    chartData={resourceData?.resource_access}
                    chartLoader={chartLoader}
                  />
                </Col>
              ) : (
                <Col md={12} style={{ height: "360px" }}>
                  <MyPieChart
                    labels={this.state.chartLabels}
                    series={this.state.chartSeries}
                    chartData={resourceData?.resource_access}
                    chartLoader={chartLoader}
                  />
                </Col>
              )}
              <Col
                md={6}
                className="d-flex justify-content-start  align-items-center mt-1"
              >
                <Totalcount>
                  <TotalCountText> Total Count : </TotalCountText>
                  {resourceData?.total_resource_access_count}
                </Totalcount>
              </Col>
              <Col md={6} className="d-flex justify-content-end mt-1">
                <Dropdown
                  onSelect={(eventKey) => this.handleSelectChart(eventKey)}
                >
                  <CustomDropdownToggle id="dropdown-basic">
                    {selectedChart}
                  </CustomDropdownToggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="Donut">Donut</Dropdown.Item>
                    {/* <Dropdown.Item eventKey="Bar">Bar</Dropdown.Item> */}
                    <Dropdown.Item eventKey="Pie">Pie</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </>
          ) : (
            <Col xs={12} className="px-2 ">
              <div className="container-fluid">
                <div
                  className="loader-box"
                  style={{ flex: 1, justifyContent: "center" }}
                >
                  <div className="loader">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>

        {/* Child Modal for Fullscreen Resource Access */}
        <Modal
          show={showChildModal}
          onHide={() => this.setState({ showChildModal: false })}
          size="lg"
        >
          <Modal.Body>
            {/* <ResourceAccess {...this.props} fullscreen={true} />{" "} */}
            <ResourceAccess
              {...this.props}
              fullscreen={true}
              selectedOption={this.state.selectedOption}
              selectedChart={this.state.selectedChart}
              output={this.state.output}
            />
            {/* Pass fullscreen=true */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showChildModal: false })}
              className="mx-3"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DateRangePicker
              initialSettings={{
                startDate: this.state.fromDate.toDate(),
                endDate: this.state.toDate.toDate(),
                maxDate: moment().toDate(),
              }}
              onApply={(event, picker) =>
                this.handleSelectDateRange("Custom Date", picker)
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                }}
              >
                Choose Custom Dates
              </div>
            </DateRangePicker>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ResourceAccess;
