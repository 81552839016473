import React, { Component } from "react";
import {
  Row,
  Col,
  Dropdown,
  Modal,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { API_BASE_TEST } from "../../../constant/api"; // Import your API base

// Ensure the image path is correct
import TopDownloads from "../../../assets/images/common/top-downloads.png";
import { ReactComponent as ZoomIcon } from "../../../assets/images/common/clip-modal-icon.svg";
import NoResultImg from "../../../assets/images/common/noresults.jpg";

// Array of 10 background colors for the initials' circle
const colors = [
  "#FF5733",
  "#33FF57",
  "#3357FF",
  "#FF33A1",
  "#FF8C33",
  "#33FFD4",
  "#D433FF",
  "#FF3333",
  "#33FFB8",
  "#FFD433",
];

const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  const initials =
    nameParts[0].charAt(0) + (nameParts[1] ? nameParts[1].charAt(0) : "");
  return initials.toUpperCase();
};

const getRandomColor = (index) => {
  return colors[index % colors.length];
};

const truncateText = (text) => {
  if (!text) return "";
  return text.length > 15 ? `${text.substring(0, 15)}...` : text;
};

// Styled components
const Title = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #0e1726;
`;

const Totalcount = styled.div`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #555555;
  margin-bottom: 20px;
`;

const UniversityItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  font-family: Nunito;
`;

const UniversityInfo = styled.div`
  width: 30%;
`;

const UniversityDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UniversityName = styled.h3`
  font-family: Nunito;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
`;

const UniversityLocation = styled.p`
  font-family: Nunito;
  margin: 5px 0;
  font-size: 12px;
  color: #999;
`;

const ProgressBarContainer = styled.div`
  width: 60%;
  align-items: center;
  margin-left: 20px;
`;

// const ProgressBar = styled.div`
//   position: relative;
//   height: 20px;
//   width: 100%;
//   background-color: #e0e0e0;
//   border-radius: 10px;
// `;

// const Filler = styled.div`
//   height: 100%;
//   background-color: #713aff;
//   border-radius: 10px;
//   width: ${(props) => props.width}%;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   padding-right: 10px;
// `;

// const Label = styled.span`
//   font-size: 12px;
//   color: #fff;
//   font-weight: 600;
// `;

const ProgressBar = styled.div`
  position: relative;
  height: 20px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
`;

const Filler = styled.div`
  height: 100%;
  background-color: #713aff;
  border-radius: 10px;
  width: ${(props) => props.width}%;
  position: relative; /* Ensure the label can be positioned relative to the bar */
`;

const Label = styled.span`
  position: absolute;
  color: #000;
  top: 50%;
  right: -50px; /* Move slightly outside the bar */
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: 600;
`;

const CustomDropdownToggle = styled(Dropdown.Toggle)`
  background-color: #f7f7f7 !important;
  border: 1px solid #d9d9d9 !important;
  color: #000 !important;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  line-height: 19.1px;
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: #f7f7f7 !important;
    border: 1px solid #d9d9d9 !important;
  }
`;

class TopDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.selectedOption || "One Week",
      topCount: props.topCount || 5,
      fromDate: props.fromDate || moment().subtract(7, "days").startOf("day"),
      toDate: props.toDate || moment().endOf("day"),
      output: {
        type: props.selectedOption || "One Week",
        startDate: (
          props.fromDate || moment().subtract(7, "days").startOf("day")
        ).format("YYYY-MM-DD"),
        endDate: (props.toDate || moment().endOf("day")).format("YYYY-MM-DD"),
      },
      showModal: false,
      showChildModal: false,
      resourceData: null,
      chartLoader: true,
    };
  }

  componentDidMount() {
    this.fetchResourceAccessData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.libraryId !== this.props.libraryId ||
      prevState.output.startDate !== this.state.output.startDate ||
      prevState.output.endDate !== this.state.output.endDate ||
      prevState.topCount !== this.state.topCount
    ) {
      this.fetchResourceAccessData();
    }
  }

  fetchResourceAccessData = () => {
    const { libraryId } = this.props;
    const { output, topCount } = this.state;

    // Check if libraryId is present, if not, exit early
    if (!libraryId) {
      console.warn("Library ID not provided, skipping API call.");
      return; // Exit the function without making the API call
    }

    this.setState({
      chartLoader: true,
    });

    let url = `${API_BASE_TEST}v2/dashboard/top-downloads?fromDate=${output.startDate}&toDate=${output.endDate}&top=${topCount}`;

    if (libraryId) {
      url += `&libraryId=${libraryId}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Calculate the total count
        const totalCount = data?.data?.top_downloads?.reduce(
          (total, item) => total + item.count,
          0
        );

        // Add percentage to each resource
        const resourceDataWithPercentage = data?.data?.top_downloads.map(
          (item, index) => ({
            ...item,
            percentage: totalCount
              ? Number(((item.count / totalCount) * 100).toFixed(2))
              : 0, // Keeping two decimal places
            bgColor: getRandomColor(index),
          })
        );

        this.setState({
          resourceData: resourceDataWithPercentage,
          chartLoader: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching resource access data:", error);
      });
  };

  handleSelectDateRange = (option, picker = null) => {
    let fromDate = moment();
    let toDate = moment();
    let output = { type: option, startDate: "", endDate: "" };

    if (option === "One Week") {
      fromDate = moment().subtract(7, "days");
    } else if (option === "Last 15 Days") {
      fromDate = moment().subtract(15, "days");
    } else if (option === "Last 30 Days") {
      fromDate = moment().subtract(30, "days");
    } else if (option === "This Month") {
      fromDate = moment().startOf("month");
      toDate = moment();
    } else if (option === "Custom Date" && picker) {
      fromDate = picker.startDate;
      toDate = picker.endDate;
    } else if (option === "Custom Date") {
      this.setState({ showModal: true, selectedOption: option });
      return;
    }

    output.startDate = fromDate.format("YYYY-MM-DD");
    output.endDate = toDate.format("YYYY-MM-DD");

    this.setState({
      selectedOption: option,
      fromDate,
      toDate,
      output,
      showModal: false,
    });
  };

  handleSelectTopCount = (count) => {
    this.setState({ topCount: parseInt(count) });
  };

  handleZoomClick = () => {
    this.setState({ showChildModal: true });
  };

  render() {
    const {
      showModal,
      showChildModal, // Modal visibility state
      selectedOption,
      topCount,
      fromDate,
      toDate,
      output,
      resourceData,
      chartLoader,
    } = this.state;
    const { fullscreen } = this.props; // Add fullscreen prop

    return (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          padding: "10px 20px",
          boxShadow: "1px 5px 24px 0 rgba(68, 102, 242, 0.05)",
          height: "470px",
        }}
      >
        <Row>
          <Col md={12} className="d-flex justify-content-between">
            <div>
              <Title>Top Downloads</Title>
              <Totalcount>
                {moment(output?.startDate)?.format("MMM-DD, YYYY")} to{" "}
                {moment(output?.endDate)?.format("MMM-DD, YYYY")}
              </Totalcount>
            </div>
            <div className="d-flex justify-content-end">
              <Dropdown
                onSelect={(eventKey) => this.handleSelectDateRange(eventKey)}
                className="mx-2"
              >
                <CustomDropdownToggle id="dropdown-basic">
                  {selectedOption}
                </CustomDropdownToggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="One Week">One Week</Dropdown.Item>
                  <Dropdown.Item eventKey="Last 15 Days">
                    Last 15 Days
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Last 30 Days">
                    Last 30 Days
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="This Month">
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Custom Date">
                    Custom Date
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown
                onSelect={(eventKey) => this.handleSelectTopCount(eventKey)}
                className={`${!fullscreen ? "mx-2" : ""}`}
              >
                <CustomDropdownToggle id="dropdown-count">
                  Top {topCount}
                </CustomDropdownToggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="5">Top 5</Dropdown.Item>
                  <Dropdown.Item eventKey="10">Top 10</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {!fullscreen && ( // Conditionally render ZoomIcon
                <ZoomIcon
                  onClick={this.handleZoomClick} // Add onClick to ZoomIcon
                  style={{
                    height: "20px",
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                />
              )}
            </div>
          </Col>
          {!chartLoader ? (
            <Col md={12} style={{ height: 350, overflowY: "auto" }}>
              {resourceData?.length > 0 ? (
                resourceData?.map((resource) => (
                  <UniversityItem key={resource?._id}>
                    <Avatar bgColor={resource.bgColor}>
                      {getInitials(resource?.resourceName)}
                    </Avatar>
                    <UniversityInfo>
                      <UniversityDetails>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>{`${resource?.resourceName}`}</Tooltip>
                          }
                        >
                          <UniversityName>
                            {truncateText(resource?.resourceName)}
                          </UniversityName>
                        </OverlayTrigger>
                        <UniversityLocation>
                          {resource.location}
                        </UniversityLocation>
                      </UniversityDetails>
                    </UniversityInfo>
                    <ProgressBarContainer>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>{`${resource?.percentage}%`}</Tooltip>
                        }
                      >
                        {/* <ProgressBar>
                          <Filler width={resource?.percentage}>
                            <Label>{`${resource?.percentage}%`}</Label>
                          </Filler>
                        </ProgressBar>
                        <ProgressBar> */}
                        <ProgressBar>
                          <Filler width={resource?.percentage}>
                            <Label>{`${resource?.percentage}%`}</Label>
                          </Filler>
                        </ProgressBar>
                      </OverlayTrigger>
                    </ProgressBarContainer>
                  </UniversityItem>
                ))
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    fontFamily: "Nunito",
                  }}
                >
                  No record found <br />
                  <img
                    src={NoResultImg}
                    alt="No record found"
                    style={{ width: "300px", height: "300px" }}
                  />
                </div>
              )}
            </Col>
          ) : (
            <Col xs={12} className="px-2">
              <div
                className="loader-box"
                style={{ flex: 1, justifyContent: "center" }}
              >
                <div className="loader">
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
              </div>
            </Col>
          )}
        </Row>

        {/* Child Modal for Fullscreen Resource Access */}
        <Modal
          show={showChildModal}
          onHide={() => this.setState({ showChildModal: false })}
          size="lg"
        >
          <Modal.Body>
            <TopDownload
              {...this.props}
              fullscreen={true}
              selectedOption={selectedOption} // Pass selectedOption
              topCount={topCount} // Pass topCount
              fromDate={fromDate} // Pass fromDate
              toDate={toDate} // Pass toDate
            />{" "}
            {/* Pass fullscreen=true */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showChildModal: false })}
              className="mx-3"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DateRangePicker
              initialSettings={{
                startDate: fromDate.toDate(),
                endDate: toDate.toDate(),
                maxDate: moment().toDate(),
              }}
              onApply={(event, picker) =>
                this.handleSelectDateRange("Custom Date", picker)
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                }}
              >
                Choose Custom Dates
              </div>
            </DateRangePicker>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default TopDownload;
