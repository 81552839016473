import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import Select from "react-select";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API_BASE, API_BASE_TEST } from "../../constant/api";
import "react-toastify/dist/ReactToastify.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import ResourceAccess from "./resource-access";
import PdfReports from "./pdf-reports";
import LoginStatistics from "./login-statistics";
import styled from "styled-components";
import * as XLSX from "xlsx";
import { ProgressBar } from "react-bootstrap";
import FilterResultImg from "../../assets/images/common/filterresult.jpg";

const FilterTitle = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  color: #0e1726;
`;

let userDetails;

class UserActivityReport extends Component {
  constructor(props) {
    super(props);
    userDetails = JSON.parse(localStorage.getItem("user"));
    this.state = {
      selectedGroup: null,
      selectedCollege: userDetails?.Groups?.Name ? { label: userDetails?.Groups?._id, value: userDetails?.Groups?._id } : null,
      selectedLibrary: userDetails?.Groups?.Name ? { label: userDetails?.Groups?.Library, value: userDetails?.Groups?.Library } : null,
      selectedResource: null,
      fromDate: moment().startOf("month"),
      toDate: moment(),
      libraries: [],
      colleges: [],
      groups: [],
      resources: [],
      page: 1,
      pageSize: 20,
      hasMore: true,
      loadingMore: false,
      searchValue: "",
      chartLoading: true,
      showCharts: false,
      groupLoading: true,
      collegeLoading: true,
      libraryLoading: true,
      resourceLoading: true,
      downloadProgress: 0,
      showProgressBar: false,
      downloadStatus: "Processing...",
      pdfDownloadsLoading: false,
      resourceAccessLoading: false,
      loginLoading: false,
      selectedYear: null, // Year selection
      selectedMonths: [], // Months selection
      showModal: false, // State to show the modal
      debounceTimeout: null,
    };
  }

  handleSelectChange = (selectedOption, key) => {
    this.setState({ [key]: selectedOption });
  };

  // Handle year selection
  handleYearSelect = (selectedYear) => {
    this.setState({ selectedYear });
  };

  // Handle month checkbox toggle
  handleMonthToggle = (month) => {
    const { selectedMonths } = this.state;
    if (selectedMonths.includes(month)) {
      this.setState({
        selectedMonths: selectedMonths.filter((m) => m !== month),
      });
    } else {
      this.setState({ selectedMonths: [...selectedMonths, month] });
    }
  };

  // Handle form submission for year and month selection

  // handleSubmitSelection = async () => {
  //   const {
  //     selectedYear,
  //     selectedMonths,
  //     selectedLibrary,
  //     selectedCollege,
  //     selectedGroup,
  //     selectedResource,
  //   } = this.state;

  //   console.log("Selected Year:", selectedMonths);

  //   // Ensure there are selected months
  //   if (selectedMonths.length > 0) {
  //     for (let i = 0; i < selectedMonths.length; i++) {
  //       const monthNumber = moment(selectedMonths[i], "MMMM").month() + 1; // Convert month name to month number
  //       const fromDate = moment(
  //         `${selectedYear}-${monthNumber}-01`,
  //         "YYYY-MM-DD"
  //       ).format("YYYY-MM-DD");

  //       const toDate = moment(fromDate).endOf("month").format("YYYY-MM-DD");

  //       // Set "Processing..." text before starting the download
  //       this.setState({
  //         showProgressBar: true,
  //         downloadStatus: "Processing " + selectedMonths[i] + selectedYear,
  //         downloadProgress: 0,
  //       });

  //       // Fetch the exportExcel data
  //       const queryParams = new URLSearchParams();

  //       if (selectedGroup)
  //         queryParams.append("resourceCategoryId", selectedGroup.value);
  //       if (selectedCollege)
  //         queryParams.append("collegeId", selectedCollege.value);
  //       if (selectedLibrary && selectedLibrary.value) {
  //         queryParams.append("libraryId", selectedLibrary.value);
  //       } else {
  //         const userLibraryId = JSON.parse(localStorage.getItem("user"))
  //           ?.Library?._id;
  //         if (userLibraryId) {
  //           queryParams.append("libraryId", userLibraryId);
  //         } else {
  //           this.setState({ showProgressBar: false, downloadStatus: "" });
  //           toast.error("Library must be selected to view report!", {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //           return; // Prevent further execution
  //         }
  //       }

  //       if (selectedResource)
  //         queryParams.append("resourceId", selectedResource.value);
  //       queryParams.append("fromDate", fromDate || "");
  //       queryParams.append("toDate", toDate || "");

  //       const exportExcelUrl = `${API_BASE_TEST}v2/report/export-excel?${queryParams.toString()}`;

  //       try {
  //         this.setState({
  //           showModal: false, // Close modal
  //         });

  //         // Switch to "Downloading..." when the request is made
  //         this.setState({
  //           showProgressBar: true,
  //           downloadStatus: "Processing...",
  //         });

  //         const response = await fetch(exportExcelUrl, {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //         });

  //         if (!response.ok) {
  //           throw new Error("Failed to fetch data from the API");
  //         }

  //         // const reader = response.body.getReader();
  //         // const contentLength = +response.headers.get("Content-Length");
  //         // let receivedLength = 0; // How many bytes we've received
  //         // let chunks = []; // Array to accumulate binary data

  //         // while (true) {
  //         //   const { done, value } = await reader.read();

  //         //   if (done) break;

  //         //   chunks.push(value);
  //         //   receivedLength += value.length;

  //         //   const percentageLoaded = ((receivedLength / contentLength) * 100).toFixed(2);

  //         //   this.setState({
  //         //     showProgressBar: true,
  //         //     downloadStatus: `Downloading ${selectedMonths[i]} ${selectedYear}`,
  //         //     downloadProgress: percentageLoaded,
  //         //   });
  //         // }

  //         // Parse the response as JSON
  //         const data = await response.json();

  //         // Log the entire data response to check what is returned

  //         if (data && data.status) {
  //           const result = data;
  //           console.log("API Response:", result);

  //           if (!result) {
  //             throw new Error("No data found in the response");
  //           }

  //           // Process the data as you are doing
  //           const loginCharts = result?.data?.login_charts?.[0] || {
  //             colleges: [],
  //             totalLoginCount: 0,
  //           };
  //           const resourceAccess = result?.data?.resource_access || [];
  //           const pdfDownloads = result?.data?.pdf_downloads || [];
  //           const loginAccess = result?.data?.login_access || [];

  //           // Log parsed sections to ensure data is correctly populated
  //           console.log("Login Charts:", loginCharts);
  //           console.log("Resource Access:", resourceAccess);
  //           console.log("PDF Downloads:", pdfDownloads);
  //           console.log("Login Access:", loginAccess);

  //           // Define default headers
  //           const loginChartsData = [
  //             ["College Name", "Login Count"],
  //             ...loginCharts?.colleges.map((college) => [
  //               college?.CollegeName,
  //               college?.CountOfLoginId,
  //             ]),
  //             ["Grand Total", loginCharts?.totalLoginCount],
  //           ];

  //           const resourceAccessData = [
  //             [
  //               "Library Name",
  //               "Login ID",
  //               "College Name",
  //               "Resource Name",
  //               "Resource Url Address",
  //               "Resource Group Name",
  //               "Access date (IST)",
  //               "User IP Address",
  //               "User City",
  //               "User Region",
  //               "User Country",
  //               "Device Type",
  //             ],
  //             ...resourceAccess.map((resourceaccess) => [
  //               resourceaccess?.LibraryName,
  //               resourceaccess?.UserId,
  //               resourceaccess?.CollegeName,
  //               resourceaccess?.ResourceName,
  //               resourceaccess?.RequestURL,
  //               resourceaccess?.ResourceGroupName,
  //               resourceaccess?.RequestDateTime,
  //               resourceaccess?.locationDetails?.ip,
  //               resourceaccess?.locationDetails?.city,
  //               resourceaccess?.locationDetails?.region_name,
  //               resourceaccess?.locationDetails?.country_name,
  //               resourceaccess?.UserAgent,
  //             ]),
  //           ];

  //           const pdfDownloadsData = [
  //             [
  //               "Library Name",
  //               "Login ID",
  //               "College Name",
  //               "Resource Name",
  //               "Resource Url Address",
  //               "Resource Group Name",
  //               "Access date (IST)",
  //               "User IP Address",
  //               "User City",
  //               "User Region",
  //               "User Country",
  //               "Device Type",
  //             ],
  //             ...pdfDownloads.map((pdfdownloads) => [
  //               pdfdownloads?.LibraryName,
  //               pdfdownloads?.UserId,
  //               pdfdownloads?.CollegeName,
  //               pdfdownloads?.ResourceName,
  //               pdfdownloads?.RequestURL,
  //               pdfdownloads?.ResourceGroupName,
  //               pdfdownloads?.RequestDateTime,
  //               pdfdownloads?.locationDetails?.ip,
  //               pdfdownloads?.locationDetails?.city,
  //               pdfdownloads?.locationDetails?.region_name,
  //               pdfdownloads?.locationDetails?.country_name,
  //               pdfdownloads?.UserAgent,
  //             ]),
  //           ];

  //           const loginAccessData = [
  //             [
  //               "Library Name",
  //               "Login ID",
  //               "Auth Type",
  //               "College Name",
  //               "Access Time",
  //               "IP",
  //               "Country",
  //               "Region",
  //               "City",
  //               "User Agent",
  //             ],
  //             ...loginAccess.map((loginaccess) => [
  //               loginaccess?.LibraryName,
  //               loginaccess?.UserName,
  //               loginaccess?.AuthType,
  //               loginaccess?.CollegeName,
  //               loginaccess?.RequestDateTime,
  //               loginaccess?.locationDetails?.ip,
  //               loginaccess?.locationDetails?.country_name,
  //               loginaccess?.locationDetails?.region_name,
  //               loginaccess?.locationDetails?.city,
  //               loginaccess?.UserAgent,
  //             ]),
  //           ];

  //           const workbook = XLSX.utils.book_new();

  //           const getMaxLength = (data, colIdx) =>
  //             Math.max(...data.map((row) => String(row[colIdx] || "").length));

  //           const setColumnWidths = (sheet, data) => {
  //             const colWidths = [];
  //             for (let i = 0; i < data[0].length; i++) {
  //               colWidths.push({ wch: getMaxLength(data, i) });
  //             }
  //             sheet["!cols"] = colWidths;
  //           };

  //           const styleHeaders = (sheet, headers) => {
  //             headers.forEach((header, index) => {
  //               const cellAddress = XLSX.utils.encode_cell({
  //                 c: index,
  //                 r: 0,
  //               });
  //               const cell = sheet[cellAddress];
  //               if (cell) {
  //                 cell.s = {
  //                   font: { bold: true },
  //                   fill: {
  //                     fgColor: { rgb: "FFFF00" },
  //                   },
  //                 };
  //               }
  //             });
  //           };

  //           // Append sheets even if they have empty data
  //           const appendSheet = (data, sheetName) => {
  //             const sheet = XLSX.utils.aoa_to_sheet(data);
  //             setColumnWidths(sheet, data);
  //             styleHeaders(sheet, data[0]);
  //             XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
  //           };

  //           appendSheet(loginChartsData, "Login Statistics");
  //           appendSheet(resourceAccessData, "Resource Access");
  //           appendSheet(pdfDownloadsData, "PDF Downloads");
  //           appendSheet(loginAccessData, "Login Access");

  //           // Generate and download the Excel file
  //           const libraryName =
  //             selectedLibrary?.label ||
  //             "UserActivityReport" + fromDate + toDate;
  //           XLSX.writeFile(workbook, `${libraryName}.xlsx`);

  //           setTimeout(() => {
  //             this.setState({ showProgressBar: false, downloadStatus: "" });
  //             // Clear year and months state and close modal if required
  //             this.setState({
  //               selectedYear: null,
  //               selectedMonths: [],
  //             });
  //           }, 1000);

  //           this.setState({
  //             showProgressBar: true,
  //             downloadStatus: "Downloading",
  //           });
  //         } else {
  //           toast.error(` ${data.message} of ${selectedMonths[i]} month`, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         }
  //       } catch (error) {
  //         console.error("Failed to fetch exportExcel data:", error);
  //         toast.error(`Failed to fetch data: ${error.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         this.setState({ showProgressBar: false, downloadStatus: "" });
  //       }
  //     }
  //   } else {
  //     toast.error("Months and Year must be selected.", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   }
  // };

  handleSubmitSelection = async () => {
    const {
      selectedYear,
      selectedMonths,
      selectedLibrary,
      selectedCollege,
      selectedGroup,
      selectedResource,
    } = this.state;

    // Ensure there are selected months
    if (selectedMonths.length > 0) {
      for (let i = 0; i < selectedMonths.length; i++) {
        const monthNumber = moment(selectedMonths[i], "MMMM").month() + 1; // Convert month name to month number
        const fromDate = moment(
          `${selectedYear}-${monthNumber}-01`,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD");
        const toDate = moment(fromDate).endOf("month").format("YYYY-MM-DD");

        // Set "Processing..." text before starting the download
        this.setState({
          showProgressBar: true,
          downloadStatus: "Processing " + selectedMonths[i] + selectedYear,
          downloadProgress: 0,
        });

        // Fetch the exportExcel data
        const queryParams = new URLSearchParams();

        if (selectedGroup)
          queryParams.append("resourceCategoryId", selectedGroup.value);
        if (selectedCollege)
          queryParams.append("collegeId", selectedCollege.value);
        if (selectedLibrary && selectedLibrary.value) {
          queryParams.append("libraryId", selectedLibrary.value);
        } else {
          const userLibraryId = JSON.parse(localStorage.getItem("user"))
            ?.Library?._id;
          if (userLibraryId) {
            queryParams.append("libraryId", userLibraryId);
          } else {
            this.setState({ showProgressBar: false, downloadStatus: "" });
            toast.error("Library must be selected to view report!", {
              position: toast.POSITION.TOP_CENTER,
            });
            return; // Prevent further execution
          }
        }

        if (selectedResource)
          queryParams.append("resourceId", selectedResource.value);
        queryParams.append("fromDate", fromDate || "");
        queryParams.append("toDate", toDate || "");

        const exportExcelUrl = `${API_BASE_TEST}v2/report/export-excel?${queryParams.toString()}`;

        try {
          this.setState({
            showModal: false, // Close modal
          });

          // Switch to "Downloading..." when the request is made
          this.setState({
            showProgressBar: true,
            downloadStatus:
              "Processing " + selectedMonths[i] + "  " + selectedYear,
          });

          const response = await fetch(exportExcelUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch data from the API");
          }

          // Track the progress of the download
          const contentLength = response.headers.get("Content-Length");
          const totalBytes = parseInt(contentLength, 10);

          let loadedBytes = 0;
          const reader = response.body.getReader();

          const chunks = [];
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            chunks.push(value);
            loadedBytes += value.length;

            const percentageLoaded = Math.round(
              (loadedBytes / totalBytes) * 100
            );

            this.setState({
              showProgressBar: true,
              downloadStatus: `Downloading ${selectedMonths[i]} ${selectedYear}`,
              downloadProgress: percentageLoaded,
            });
          }

          // Decode the chunks into a complete data response
          const blob = new Blob(chunks);
          const data = await blob.text(); // Assuming the response is in JSON format
          const result = JSON.parse(data);

          // Process the data as you are doing
          const loginCharts = result?.data?.login_charts?.[0] || {
            colleges: [],
            totalLoginCount: 0,
          };
          const resourceAccess = result?.data?.resource_access || [];
          const pdfDownloads = result?.data?.pdf_downloads || [];
          const loginAccess = result?.data?.login_access || [];

          const loginChartsData = [
            ["College Name", "Login Count"],
            ...loginCharts?.colleges.map((college) => [
              college?.CollegeName,
              college?.CountOfLoginId,
            ]),
            ["Grand Total", loginCharts?.totalLoginCount],
          ];

          const resourceAccessData = [
            [
              "Library Name",
              "Login ID",
              "College Name",
              "Resource Name",
              "Resource Url Address",
              "Resource Group Name",
              "Access date (IST)",
              // "User IP Address",
              // "User City",
              // "User Region",
              // "User Country",
              "Device Type",
            ],
            ...resourceAccess.map((resourceaccess) => [
              resourceaccess?.LibraryName,
              resourceaccess?.UserId,
              resourceaccess?.CollegeName,
              resourceaccess?.ResourceName,
              resourceaccess?.RequestURL,
              resourceaccess?.ResourceGroupName,
              resourceaccess?.RequestDateTime,
              // resourceaccess?.locationDetails?.ip,
              // resourceaccess?.locationDetails?.city,
              // resourceaccess?.locationDetails?.region_name,
              // resourceaccess?.locationDetails?.country_name,
              resourceaccess?.UserAgent,
            ]),
          ];

          const pdfDownloadsData = [
            [
              "Library Name",
              "Login ID",
              "College Name",
              "Resource Name",
              "Resource Url Address",
              "Resource Group Name",
              "Access date (IST)",
              // "User IP Address",
              // "User City",
              // "User Region",
              // "User Country",
              "Device Type",
            ],
            ...pdfDownloads.map((pdfdownloads) => [
              pdfdownloads?.LibraryName,
              pdfdownloads?.UserId,
              pdfdownloads?.CollegeName,
              pdfdownloads?.ResourceName,
              pdfdownloads?.RequestURL,
              pdfdownloads?.ResourceGroupName,
              pdfdownloads?.RequestDateTime,
              // pdfdownloads?.locationDetails?.ip,
              // pdfdownloads?.locationDetails?.city,
              // pdfdownloads?.locationDetails?.region_name,
              // pdfdownloads?.locationDetails?.country_name,
              pdfdownloads?.UserAgent,
            ]),
          ];

          const loginAccessData = [
            [
              "Library Name",
              "Login ID",
              "Auth Type",
              "College Name",
              "Access Time",
              // "IP",
              // "Country",
              // "Region",
              // "City",
              "User Agent",
            ],
            ...loginAccess.map((loginaccess) => [
              loginaccess?.LibraryName,
              loginaccess?.UserName,
              loginaccess?.AuthType,
              loginaccess?.CollegeName,
              loginaccess?.RequestDateTime,
              // loginaccess?.locationDetails?.ip,
              // loginaccess?.locationDetails?.country_name,
              // loginaccess?.locationDetails?.region_name,
              // loginaccess?.locationDetails?.city,
              loginaccess?.UserAgent,
            ]),
          ];

          const workbook = XLSX.utils.book_new();

          const getMaxLength = (data, colIdx) =>
            Math.max(...data.map((row) => String(row[colIdx] || "").length));

          const setColumnWidths = (sheet, data) => {
            const colWidths = [];
            for (let i = 0; i < data[0].length; i++) {
              colWidths.push({ wch: getMaxLength(data, i) });
            }
            sheet["!cols"] = colWidths;
          };

          const styleHeaders = (sheet, headers) => {
            headers.forEach((header, index) => {
              const cellAddress = XLSX.utils.encode_cell({
                c: index,
                r: 0,
              });
              const cell = sheet[cellAddress];
              if (cell) {
                cell.s = {
                  font: { bold: true },
                  fill: {
                    fgColor: { rgb: "FFFF00" },
                  },
                };
              }
            });
          };

          const appendSheet = (data, sheetName) => {
            const sheet = XLSX.utils.aoa_to_sheet(data);
            setColumnWidths(sheet, data);
            styleHeaders(sheet, data[0]);
            XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
          };

          if (!userDetails?.College?._id) {
            appendSheet(loginChartsData, "Login Statistics");

          }
          appendSheet(resourceAccessData, "Resource Access");
          appendSheet(pdfDownloadsData, "PDF Downloads");
          appendSheet(loginAccessData, "Login Access");

          const libraryName = selectedLibrary?.label || "UserActivityReport";
          XLSX.writeFile(
            workbook,
            `${libraryName + selectedMonths[i] + selectedYear}.xlsx`
          );
          this.setState({ showProgressBar: false, downloadStatus: "" });
          setTimeout(() => {
            this.setState({
              selectedYear: null,
              selectedMonths: [],
            });
          }, 1000);
        } catch (error) {
          console.error("Failed to fetch exportExcel data:", error);
          toast.error(`Failed to fetch data: ${error.message}`, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ showProgressBar: false, downloadStatus: "" });
        }
      }
    } else {
      toast.error("Months and Year must be selected.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // Fetch libraries with pagination and search
  getNewLibraries = (page = 1) => {
    const { pageSize, searchValue } = this.state;

    var url = new URL(`${API_BASE_TEST}v2/common/library/list`);
    url.searchParams.append("page", page);
    url.searchParams.append("page_size", pageSize);

    if (searchValue) {
      url.searchParams.append("search", searchValue);
    }

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.status) {
          const newLibraries = res.data.libraries.map((v) => ({
            value: v._id,
            label: v.UniversityName,
          }));

          this.setState((prevState) => ({
            libraries:
              page === 1
                ? newLibraries
                : [...prevState.libraries, ...newLibraries],
            page: page + 1,
            hasMore: newLibraries.length === pageSize,
            loadingMore: false,
            libraryLoading: false,
          }));
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          toast.error("Failed to fetch libraries!", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ loadingMore: false });
        }
      });
  };

  // Fetch resources with pagination and search
  getResources = (page = 1, selectedLib) => {
    const { pageSize, searchValue } = this.state;

    var url = new URL(`${API_BASE_TEST}v2/common/resource/list`);
    url.searchParams.append("page", page);
    url.searchParams.append("page_size", pageSize);

    if (searchValue) {
      url.searchParams.append("search", searchValue);
    }

    // Get the user object from localStorage and check the role
    const user = JSON.parse(localStorage.getItem("user"));
    const isLibraryAdmin = user?.Role?.Name === "Library Admin";

    // If user is Library Admin and library ID exists, append it to the request
    if (isLibraryAdmin && user?.Library?._id) {
      url.searchParams.append("library_id", user.Library._id);
    }
    // Selected Library
    console.log(selectedLib, "selectedLib");
    if (selectedLib !== undefined && selectedLib?.value) {
      console.log(selectedLib?.value, "selectedLib");
      url.searchParams.append("library_id", selectedLib?.value);
    }

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.status) {
          const newResources = res.data.resources.map((v) => ({
            value: v._id,
            label: v.Name,
          }));

          this.setState((prevState) => ({
            resources:
              page === 1
                ? newResources
                : [...prevState.resources, ...newResources],
            page: page + 1,
            hasMore: newResources.length === pageSize,
            loadingMore: false,
            resourceLoading: false,
          }));
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          toast.error("Failed to fetch resources!", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ loadingMore: false });
        }
      });
  };

  // Fetch groups with pagination and search
  getGroups = (page = 1, selectedLib) => {
    const { pageSize, searchValue } = this.state;

    var url = new URL(`${API_BASE_TEST}v2/common/resource-category/list`);
    url.searchParams.append("page", page);
    url.searchParams.append("page_size", pageSize);

    if (searchValue) {
      url.searchParams.append("search", searchValue);
    }

    // Get the user object from localStorage and check the role
    const user = JSON.parse(localStorage.getItem("user"));
    const isLibraryAdmin = user?.Role?.Name === "Library Admin";

    // If user is Library Admin and library ID exists, append it to the request
    if (isLibraryAdmin && user?.Library?._id) {
      url.searchParams.append("library_id", user.Library._id);
    }
    // Selected Library
    const { selectedLibrary } = this.state;
    if (selectedLib !== undefined && selectedLib?.value) {
      url.searchParams.append("library_id", selectedLib?.value);
    }

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.status) {
          const newGroups = res.data.resourceCategories.map((v) => ({
            value: v._id,
            label: v.Name,
          }));

          this.setState((prevState) => ({
            groups:
              page === 1 ? newGroups : [...prevState.groups, ...newGroups],
            page: page + 1,
            hasMore: newGroups.length === pageSize,
            loadingMore: false,
            groupLoading: false,
          }));
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          toast.error("Failed to fetch groups!", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ loadingMore: false });
        }
      });
  };

  // Fetch colleges with pagination and search
  getColleges = (page = 1, selectedLib) => {
    const { pageSize, searchValue } = this.state;

    var url = new URL(`${API_BASE_TEST}v2/common/college/list`);
    url.searchParams.append("page", page);
    url.searchParams.append("page_size", pageSize);

    if (searchValue) {
      url.searchParams.append("search", searchValue);
    }

    // Get the user object from localStorage and check the role
    const user = JSON.parse(localStorage.getItem("user"));
    const isLibraryAdmin = user?.Role?.Name === "Library Admin";

    // If user is Library Admin and library ID exists, append it to the request
    if (isLibraryAdmin && user?.Library?._id) {
      url.searchParams.append("library_id", user.Library._id);
    }
    // Selected Library
    const { selectedLibrary } = this.state;
    if (selectedLib !== undefined && selectedLib?.value) {
      url.searchParams.append("library_id", selectedLib?.value);
    }

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.status) {
          const newColleges = res.data.colleges.map((v) => ({
            value: v._id,
            label: v.CollegeName,
          }));
          this.setState((prevState) => ({
            colleges:
              page === 1
                ? newColleges
                : [...prevState.colleges, ...newColleges],
            page: page + 1,
            hasMore: newColleges.length === pageSize,
            loadingMore: false,
            collegeLoading: false,
          }));
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          toast.error("Failed to fetch colleges!", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ loadingMore: false });
        }
      });
  };

  // handleMenuScrollToBottom = (key) => {
  //   const { hasMore, loadingMore } = this.state;

  //   if (hasMore && !loadingMore) {
  //     this.setState({ loadingMore: true }, () => {
  //       if (key === "selectedLibrary") {
  //         this.getNewLibraries(this.state.page);
  //       } else if (key === "selectedResource") {
  //         this.getResources(this.state.page);
  //       } else if (key === "selectedGroup") {
  //         this.getGroups(this.state.page);
  //       } else if (key === "selectedCollege") {
  //         this.getColleges(this.state.page);
  //       }
  //     });
  //   }
  // };

  handleMenuScrollToBottom = (key) => {
    const { hasMore, loadingMore, selectedLibrary, page } = this.state;

    if (hasMore && !loadingMore) {
      this.setState({ loadingMore: true }, () => {
        if (key === "selectedGroup") {
          this.getGroups(page, selectedLibrary); // Pass selectedLibrary explicitly
        } else if (key === "selectedLibrary") {
          this.getNewLibraries(page);
        } else if (key === "selectedCollege") {
          this.getColleges(page, selectedLibrary); // Pass selectedLibrary explicitly
        } else if (key === "selectedResource") {
          this.getResources(page, selectedLibrary); // Pass selectedLibrary explicitly
        }
      });
    }
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });

    // Check if the user is a Library Admin and set the filters accordingly
    if (
      JSON.parse(localStorage.getItem("user")).Role.Name === "Library Admin"
    ) {
      this.setState({
        filterlibrary: JSON.parse(localStorage.getItem("user")).Library._id,
        filteredlibrary: JSON.parse(localStorage.getItem("user")).Library._id,
      });
    }

    this.getNewLibraries();
    this.getGroups();
    this.getResources();
    this.getColleges();
  }

  handleSelectDateRange = (event, picker) => {
    this.setState({
      fromDate: moment(picker.startDate),
      toDate: moment(picker.endDate),
    });
  };

  // Handle modal close
  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedYear: null, // Clear the year selection
      selectedMonths: [], // Clear the month selection
    });
  };

  handleDownload = async () => {
    const {
      selectedGroup,
      selectedCollege,
      selectedLibrary,
      selectedResource,
      fromDate,
      toDate,
    } = this.state;

    if (
      selectedLibrary?.value === "63e5e8effb058741f8c55775" ||
      JSON.parse(localStorage.getItem("user"))?.Library?._id ===
      "63e5e8effb058741f8c55775"
    ) {
      this.setState({ showModal: true });
      return;
    }
    // Check if selectedLibrary is empty or not in localStorage
    if (
      !selectedLibrary &&
      !JSON.parse(localStorage.getItem("user"))?.Library?._id
    ) {
      this.setState({ showProgressBar: false, downloadStatus: "" });
      toast.error("Library must be selected to download report!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return; // Prevent further execution
    }

    // Set "Processing..." text before starting the download
    this.setState({ showProgressBar: true, downloadStatus: "Processing..." });

    // Fetch the exportExcel data
    const queryParams = new URLSearchParams();

    if (selectedGroup)
      queryParams.append("resourceCategoryId", selectedGroup.value);
    if (selectedCollege) queryParams.append("collegeId", selectedCollege.value);
    if (selectedLibrary && selectedLibrary.value) {
      queryParams.append("libraryId", selectedLibrary.value);
    } else {
      const userLibraryId = JSON.parse(localStorage.getItem("user"))?.Library
        ?._id;
      if (userLibraryId) {
        queryParams.append("libraryId", userLibraryId);
      } else {
        this.setState({ showProgressBar: false, downloadStatus: "" });
        toast.error("Library must be selected to view report!", {
          position: toast.POSITION.TOP_CENTER,
        });
        return; // Prevent further execution
      }
    }

    if (selectedResource)
      queryParams.append("resourceId", selectedResource.value);
    queryParams.append(
      "fromDate",
      fromDate ? fromDate.format("YYYY-MM-DD") : ""
    );
    queryParams.append("toDate", toDate ? toDate.format("YYYY-MM-DD") : "");

    const exportExcelUrl = `${API_BASE_TEST}v2/report/export-excel?${queryParams.toString()}`;

    try {
      // Switch to "Downloading..." when the request is made
      this.setState({
        showProgressBar: true,
        downloadStatus: "Processing...",
      });


      const response = await fetch(exportExcelUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.body) {
        throw new Error("ReadableStream is not supported by this browser.");
      }

      const reader = response.body.getReader();
      const contentLength = +response.headers.get("Content-Length");

      let receivedLength = 0;
      let chunks = [];

      this.setState({
        showProgressBar: true,
        downloadStatus: "Downloading",
      });

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);
        receivedLength += value.length;

        const percentageLoaded = (
          (receivedLength / contentLength) *
          100
        ).toFixed(2);
        this.setState({ downloadProgress: percentageLoaded });
        // console.log(`Downloaded: ${percentageLoaded}%`);

        if (percentageLoaded === "100.00") {
          this.setState({
            showProgressBar: true,
            downloadStatus: "Download completed!",
          });

          setTimeout(() => {
            this.setState({ showProgressBar: false, downloadStatus: "" });
          }, 1000);
        }
      }

      const chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
      }

      const resultText = new TextDecoder("utf-8").decode(chunksAll);
      const result = JSON.parse(resultText);
      if (!result.status) {
        this.setState({ showProgressBar: false, downloadStatus: "" });
        toast.error(result.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      const loginCharts = result?.data?.login_charts?.[0] || {
        colleges: [],
        totalLoginCount: 0,
      };
      const resourceAccess = result?.data?.resource_access || [];
      const pdfDownloads = result?.data?.pdf_downloads || [];
      const loginAccess = result?.data?.login_access || [];

      // Define default headers
      const loginChartsData = [
        ["College Name", "Login Count"],
        ...loginCharts?.colleges.map((college) => [
          college?.CollegeName,
          college?.CountOfLoginId,
        ]),
        ["Grand Total", loginCharts?.totalLoginCount],
      ];

      const resourceAccessData = [
        [
          "Library Name",
          "Login ID",
          "College Name",
          "Resource Name",
          "Resource Url Address",
          "Resource Group Name",
          "Access date (IST)",
          // "User IP Address",
          // "User City",
          // "User Region",
          // "User Country",
          "Device Type",
        ],
        ...resourceAccess.map((resourceaccess) => [
          resourceaccess?.LibraryName,
          resourceaccess?.UserId,
          resourceaccess?.CollegeName,
          resourceaccess?.ResourceName,
          resourceaccess?.RequestURL,
          resourceaccess?.ResourceGroupName,
          resourceaccess?.RequestDateTime,
          // resourceaccess?.locationDetails?.ip,
          // resourceaccess?.locationDetails?.city,
          // resourceaccess?.locationDetails?.region_name,
          // resourceaccess?.locationDetails?.country_name,
          resourceaccess?.UserAgent,
        ]),
      ];

      const pdfDownloadsData = [
        [
          "Library Name",
          "Login ID",
          "College Name",
          "Resource Name",
          "Resource Url Address",
          "Resource Group Name",
          "Access date (IST)",
          // "User IP Address",
          // "User City",
          // "User Region",
          // "User Country",
          "Device Type",
        ],
        ...pdfDownloads.map((pdfdownloads) => [
          pdfdownloads?.LibraryName,
          pdfdownloads?.UserId,
          pdfdownloads?.CollegeName,
          pdfdownloads?.ResourceName,
          pdfdownloads?.RequestURL,
          pdfdownloads?.ResourceGroupName,
          pdfdownloads?.RequestDateTime,
          // pdfdownloads?.locationDetails?.ip,
          // pdfdownloads?.locationDetails?.city,
          // pdfdownloads?.locationDetails?.region_name,
          // pdfdownloads?.locationDetails?.country_name,
          pdfdownloads?.UserAgent,
        ]),
      ];

      const loginAccessData = [
        [
          "Library Name",
          "Login ID",
          "Auth Type",
          "College Name",
          "Access Time",
          // "IP",
          // "Country",
          // "Region",
          // "City",
          "User Agent",
        ],
        ...loginAccess.map((loginaccess) => [
          loginaccess?.LibraryName,
          loginaccess?.UserName,
          loginaccess?.AuthType,
          loginaccess?.CollegeName,
          loginaccess?.RequestDateTime,
          // loginaccess?.locationDetails?.ip,
          // loginaccess?.locationDetails?.country_name,
          // loginaccess?.locationDetails?.region_name,
          // loginaccess?.locationDetails?.city,
          loginaccess?.UserAgent,
        ]),
      ];

      const workbook = XLSX.utils.book_new();

      const getMaxLength = (data, colIdx) =>
        Math.max(...data.map((row) => String(row[colIdx] || "").length));

      const setColumnWidths = (sheet, data) => {
        const colWidths = [];
        for (let i = 0; i < data[0].length; i++) {
          colWidths.push({ wch: getMaxLength(data, i) });
        }
        sheet["!cols"] = colWidths;
      };

      const styleHeaders = (sheet, headers) => {
        headers.forEach((header, index) => {
          const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
          const cell = sheet[cellAddress];
          if (cell) {
            cell.s = {
              font: { bold: true },
              fill: {
                fgColor: { rgb: "FFFF00" },
              },
            };
          }
        });
      };

      // Append sheets even if they have empty data
      const appendSheet = (data, sheetName) => {
        const sheet = XLSX.utils.aoa_to_sheet(data);
        setColumnWidths(sheet, data);
        styleHeaders(sheet, data[0]);
        XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
      };

      appendSheet(loginChartsData, "Login Statistics");
      appendSheet(resourceAccessData, "Resource Access");
      appendSheet(pdfDownloadsData, "PDF Downloads");
      appendSheet(loginAccessData, "Login Access");

      // Generate and download the Excel file
      const libraryName = selectedLibrary?.label || "UserActivityReport";
      XLSX.writeFile(workbook, `${libraryName}.xlsx`);

      setTimeout(() => {
        this.setState({ showProgressBar: false, downloadStatus: "" });
      }, 1000);
    } catch (error) {
      console.error("Failed to fetch exportExcel data:", error);
      this.setState({ showProgressBar: false, downloadStatus: "" });
    }
  };

  fetchLoginStatistics = () => {
    const {
      selectedGroup,
      selectedCollege,
      selectedLibrary,
      selectedResource,
      fromDate,
      toDate,
    } = this.state;

    // Build query parameters
    const queryParams = new URLSearchParams();
    if (selectedGroup)
      queryParams.append("resourceCategoryId", selectedGroup.value);
    if (selectedCollege) queryParams.append("collegeId", selectedCollege.value);
    // if (selectedLibrary)
    //   queryParams.append(
    //     "libraryId",
    //     selectedLibrary.value ||
    //       JSON.parse(localStorage.getItem("user"))?.Library?._id
    //   );
    if (selectedLibrary && selectedLibrary.value) {
      queryParams.append("libraryId", selectedLibrary.value);
    } else {
      const userLibraryId = JSON.parse(localStorage.getItem("user"))?.Library
        ?._id;
      if (userLibraryId) {
        queryParams.append("libraryId", userLibraryId);
      } else {
        return; // Prevent further execution
      }
    }
    if (selectedResource)
      queryParams.append("resourceId", selectedResource.value);
    queryParams.append("fromDate", fromDate.format("YYYY-MM-DD"));
    queryParams.append("toDate", toDate.format("YYYY-MM-DD"));

    const loginStatisticsUrl = `${API_BASE_TEST}v2/report/login-statistics?${queryParams.toString()}`;

    this.setState({ loginLoading: true });

    fetch(loginStatisticsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((loginStatisticsData) => {
        this.setState({ loginStatisticsData, loginLoading: false });
      })
      .catch((err) => {
        console.error("Failed to fetch login statistics", err);
        toast.error("Failed to fetch login statistics", {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({ loginLoading: false });
      });
  };

  fetchResourceAccess = () => {
    const {
      selectedGroup,
      selectedCollege,
      selectedLibrary,
      selectedResource,
      fromDate,
      toDate,
    } = this.state;


    // Build query parameters
    const queryParams = new URLSearchParams();
    if (selectedGroup)
      queryParams.append("resourceCategoryId", selectedGroup.value);
    if (selectedCollege) queryParams.append("collegeId", selectedCollege.value);
    if (selectedLibrary && selectedLibrary.value) {
      queryParams.append("libraryId", selectedLibrary.value);
    } else {
      const userLibraryId = JSON.parse(localStorage.getItem("user"))?.Library
        ?._id;
      if (userLibraryId) {
        queryParams.append("libraryId", userLibraryId);
      } else {
        return; // Prevent further execution
      }
    }

    if (selectedResource)
      queryParams.append("resourceId", selectedResource.value);
    queryParams.append("fromDate", fromDate.format("YYYY-MM-DD"));
    queryParams.append("toDate", toDate.format("YYYY-MM-DD"));

    const resourceAccessUrl = `${API_BASE_TEST}v2/report/resource-access?${queryParams.toString()}`;

    this.setState({ resourceAccessLoading: true });

    fetch(resourceAccessUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((resourceAccessData) => {
        this.setState({ resourceAccessData, resourceAccessLoading: false });
      })
      .catch((err) => {
        console.error("Failed to fetch resource access data", err);
        toast.error("Failed to fetch resource access data", {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({ resourceAccessLoading: false });
      });
  };

  fetchPdfDownloads = () => {
    const {
      selectedGroup,
      selectedCollege,
      selectedLibrary,
      selectedResource,
      fromDate,
      toDate,
    } = this.state;

    // Build query parameters
    const queryParams = new URLSearchParams();
    if (selectedGroup)
      queryParams.append("resourceCategoryId", selectedGroup.value);
    if (selectedCollege) queryParams.append("collegeId", selectedCollege.value);
    if (selectedLibrary && selectedLibrary.value) {
      queryParams.append("libraryId", selectedLibrary.value);
    } else {
      const userLibraryId = JSON.parse(localStorage.getItem("user"))?.Library
        ?._id;
      if (userLibraryId) {
        queryParams.append("libraryId", userLibraryId);
      } else {
        return; // Prevent further execution
      }
    }
    if (selectedResource)
      queryParams.append("resourceId", selectedResource.value);
    queryParams.append("fromDate", fromDate.format("YYYY-MM-DD"));
    queryParams.append("toDate", toDate.format("YYYY-MM-DD"));

    const pdfDownloadsUrl = `${API_BASE_TEST}v2/report/pdf-downloads?${queryParams.toString()}`;

    this.setState({ pdfDownloadsLoading: true });

    fetch(pdfDownloadsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((pdfDownloadsData) => {
        this.setState({ pdfDownloadsData, pdfDownloadsLoading: false });
      })
      .catch((err) => {
        console.error("Failed to fetch PDF downloads data", err);
        toast.error("Failed to fetch PDF downloads data", {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({ pdfDownloadsLoading: false });
      });
  };

  fetchDataWithFilters = () => {
    const { selectedLibrary } = this.state;

    if (
      !selectedLibrary &&
      !JSON.parse(localStorage.getItem("user"))?.Library?._id
    ) {
      this.setState({ showProgressBar: false, downloadStatus: "" });
      toast.error("Library must be selected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return; // Prevent further execution
    }

    // Call each fetch function separately
    this.fetchLoginStatistics();
    this.fetchResourceAccess();
    this.fetchPdfDownloads();
  };

  handleApplyFilter = () => {
    this.setState({ showCharts: true });
    this.fetchDataWithFilters(); // Reuse the same function for manual filter application
  };

  render() {
    const { fromDate, toDate, loading, loadingMore, showCharts, showModal } =
      this.state;
    const { loginStatisticsData, resourceAccessData, pdfDownloadsData } =
      this.state;
    const { groupLoading, collegeLoading, libraryLoading, resourceLoading } =
      this.state;

    const yearOptions = Array.from(
      { length: moment().year() - 2020 },
      (_, i) => ({
        value: 2021 + i,
        label: 2021 + i,
      })
    );
    return (
      <Fragment>
        <Breadcrumb parent="false" title="User Activity Report" />
        <div className="container-fluid">
          <Row className="mb-2 px-1">
            <Col md={8}>
              <FilterTitle>Filter</FilterTitle>
            </Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-center"
            >
              {this.state.showProgressBar && (
                <ProgressBar
                  now={this.state.downloadProgress}
                  striped
                  variant="info"
                  label={
                    <span
                      style={{
                        color:
                          Number(this.state.downloadProgress)?.toFixed(1) > 40
                            ? "#fff"
                            : "#000",
                      }}
                    >{`${this.state.downloadStatus} ${this.state.downloadStatus !== "Processing..."
                      ? `(${this.state.downloadProgress})%`
                      : ""
                      }`}</span>
                  }
                  style={{ marginBottom: "0px", marginRight: "10px" }}
                  className="w-50"
                />
              )}
              <Button
                style={{
                  backgroundColor: "#17a2b8",
                  border: "none",
                  color: "#fff",
                  padding: "6px 12px",
                  borderRadius: "4px",
                }}
                onClick={this.handleDownload}
              >
                <i className="fa fa-download" aria-hidden="true" />
              </Button>
            </Col>
          </Row>
          {/* Modal for displaying year and month selection */}
          <Modal show={showModal} onHide={this.handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Select Year and Months</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <label>Select Year:</label>
                <Select
                  value={yearOptions.find(
                    (option) => option.value === this.state.selectedYear
                  )} // Correct year display
                  onChange={(option) => this.handleYearSelect(option.value)}
                  options={yearOptions}
                  placeholder="Select Year"
                />
                {!this.state.selectedYear && (
                  <small className="text-danger">Year is required</small>
                )}
              </div>

              <div className="mt-3">
                <label>Select Months:</label>
                {moment.months().map((month, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      checked={this.state.selectedMonths.includes(month)}
                      onChange={() => this.handleMonthToggle(month)}
                    />
                    <label>{month}</label>
                  </div>
                ))}
                {this.state.selectedMonths.length === 0 && (
                  <small className="text-danger">Month is required</small>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={this.handleSubmitSelection}
                disabled={
                  !this.state.selectedYear ||
                  this.state.selectedMonths.length === 0
                }
              >
                Submit Selection
              </Button>
            </Modal.Footer>
          </Modal>

          <Row className="mb-4 px-3">
            {JSON.parse(localStorage.getItem("user"))?.Role?.Name ===
              "Library Admin" || userDetails?.College?.CollegeName ? null : (
              <Col md={2} className="px-1">
                <Select
                  value={this.state.selectedLibrary}
                  onChange={(option) =>
                    this.handleSelectChange(option, "selectedLibrary")
                  }
                  options={this.state.libraries}
                  placeholder="Select Library"
                  onMenuScrollToBottom={() =>
                    this.handleMenuScrollToBottom("selectedLibrary")
                  }
                  onInputChange={(value) => {
                    // Debouncing logic directly in onInputChange
                    if (this.debounceTimeout) {
                      clearTimeout(this.debounceTimeout);
                    }

                    this.debounceTimeout = setTimeout(() => {
                      this.setState({ searchValue: value }, () => {
                        this.getNewLibraries();
                        this.getGroups(1, this.state.selectedLibrary);
                        this.getColleges(1, this.state.selectedLibrary);
                        this.getResources(1, this.state.selectedLibrary);
                      });
                    }, 300); // 300ms debounce time
                  }}
                  isLoading={libraryLoading}
                  isDisabled={loading}
                />
              </Col>
            )}
            <Col
              md={
                JSON.parse(localStorage.getItem("user"))?.Role?.Name ===
                  "Library Admin"
                  ? 3
                  : 2
              }
              className="px-1"
            >
              <Select
                value={this.state.selectedGroup}
                onChange={(option) =>
                  this.handleSelectChange(option, "selectedGroup")
                }
                options={this.state.groups}
                placeholder="Select Groups"
                onMenuScrollToBottom={() => {
                  this.handleMenuScrollToBottom("selectedGroup");
                }}
                isLoading={groupLoading}
                isDisabled={loading}
                isClearable={true}
                onInputChange={(value) => {
                  // Debouncing logic directly in onInputChange
                  if (this.debounceTimeout) {
                    clearTimeout(this.debounceTimeout);
                  }

                  this.debounceTimeout = setTimeout(() => {
                    this.setState({ searchValue: value }, () => {
                      this.getGroups(1, this.state.selectedLibrary);
                    });
                  }, 300); // 300ms debounce time
                }}
              />
            </Col>
            <Col
              md={
                JSON.parse(localStorage.getItem("user"))?.Role?.Name ===
                  "Library Admin"
                  ? 3
                  : 2
              }
              className="px-1"
            >
              {userDetails?.Groups?.Name ? <Select
                isDisabled={true}
                defaultValue={{ value: userDetails?.Groups?.Name, label: userDetails?.Groups?.Name }}
                onChange={(option) =>
                  this.handleSelectChange(option, "selectedCollege")
                }
              /> : <Select
                value={this.state.selectedCollege}
                onChange={(option) =>
                  this.handleSelectChange(option, "selectedCollege")
                }
                options={this.state.colleges}
                placeholder="Select Institutions"
                onMenuScrollToBottom={() => {
                  this.handleMenuScrollToBottom("selectedCollege");
                }}
                isLoading={collegeLoading} // Ensure collegeLoading is managed correctly
                isDisabled={loading} // Ensure loading is managed correctly
                isClearable={true}
                onInputChange={(value) => {
                  // Debouncing logic directly in onInputChange
                  if (this.debounceTimeout) {
                    clearTimeout(this.debounceTimeout);
                  }

                  this.debounceTimeout = setTimeout(() => {
                    this.setState({ searchValue: value }, async () => {
                      await this.getColleges(1, this.state.selectedLibrary);
                    });
                  }, 300); // 300ms debounce time
                }}
              />}
            </Col>

            <Col md={2} className="px-1">
              <Select
                value={this.state.selectedResource}
                onChange={(option) =>
                  this.handleSelectChange(option, "selectedResource")
                }
                options={this.state.resources}
                placeholder="Select Resources"
                onMenuScrollToBottom={() => {
                  this.handleMenuScrollToBottom("selectedResource");
                }}
                isLoading={resourceLoading}
                isDisabled={loading}
                isClearable={true}
                onInputChange={(value) => {
                  // Debouncing logic directly in onInputChange
                  if (this.debounceTimeout) {
                    clearTimeout(this.debounceTimeout);
                  }

                  this.debounceTimeout = setTimeout(() => {
                    this.setState({ searchValue: value }, () => {
                      this.getResources(1, this.state.selectedLibrary);
                    });
                  }, 300); // 300ms debounce time
                }}
              />
            </Col>
            <Col md={2} className="px-1">
              <DateRangePicker
                initialSettings={{
                  startDate: this.state.fromDate.toDate(),
                  endDate: this.state.toDate.toDate(),
                  maxDate: moment().toDate(),
                }}
                onApply={(event, picker) =>
                  this.handleSelectDateRange(event, picker)
                }
              >
                <div
                  style={{
                    cursor: "pointer",
                    padding: "8px",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  }}
                >
                  {fromDate.format("MMM-DD, YY")} to{" "}
                  {toDate.format("MMM-DD, YY")}
                </div>
              </DateRangePicker>
            </Col>
            <Col md={2} className="px-1">
              <Button
                style={{
                  backgroundColor: "#17a2b8",
                  border: "none",
                  color: "#fff",
                  padding: "6px 12px",
                  borderRadius: "4px",
                  fontWeight: "bold",
                  height: "100%",
                }}
                className="w-100"
                onClick={this.handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Col>
          </Row>

          {/* Statistics or additional content */}
          {showCharts ? (
            <Row className="mb-4">
              <Col md={12} className="mt-4">
                <LoginStatistics
                  loginStatisticsData={loginStatisticsData}
                  fromDate={fromDate}
                  toDate={toDate}
                  loading={this.state.loginLoading}
                />
              </Col>
              <Col md={12} className="mt-4">
                <ResourceAccess
                  resourceAccessData={resourceAccessData}
                  fromDate={fromDate}
                  toDate={toDate}
                  loading={this.state.resourceAccessLoading}
                />
              </Col>
              <Col md={12} className="mt-4">
                <PdfReports
                  pdfDownloadsData={pdfDownloadsData}
                  fromDate={fromDate}
                  toDate={toDate}
                  loading={this.state.pdfDownloadsLoading}
                />
              </Col>
            </Row>
          ) : (
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                padding: "20px 20px",
                boxShadow: "1px 5px 24px 0 rgba(68, 102, 242, 0.05)",
                margin: "20px 0",
                fontFamily: "Nunito",
              }}
            >
              <Row>
                <Col md={12} className="text-center">
                  <p>Apply filters to show the report.</p>
                  <img
                    src={FilterResultImg}
                    alt="Filter Result"
                    style={{ width: "500px" }}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default UserActivityReport;
