import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "./datatable";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { Parser } from 'json2csv'

class RegisterUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      users: [],
      library: {
        UniversityName: "Fetching...",
      },
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      filter: {
        email: "",
        id: "",
        username: "",
        group: "",
        loading: false,
      },
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
      userClone: [],

    };
    this.checkBoxHnadler = this.checkBoxHnadler.bind(this)
  }

  getUsers = (page = null, limit = 1000) => {
    var url = new URL(
      `${API_BASE}libraryUsers/getNotVerifiedUsers/${this.props.match.params.libid}`
    ),
      params = {};
    const urlParams = new URLSearchParams(this.props.location.search);
    if (page) params["page"] = page;
    if (limit) params["limit"] = limit;
    params["pagination"] = "off";
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            users: res.docs ? res.docs : [],
            userClone: [],
            pagination: {
              hasPrevPage: res.hasPrevPage,
              hasNextPage: res.hasNextPage,
              page: res.page,
              prevPage: res.prevPage,
              nextPage: res.nextPage,
            },
          });
        }
      });
  };

  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getUsers();
    this.getLibrary(this.props.match.params.libid);
  }




  checkBoxHnadler = (user) => {
    user.IsVerified = !user.IsVerified;
    let tempUser = {
      _id: user._id,
      IsVerified: user.IsVerified == true ? "1" : "0",
      Library: user.Library,
      Email: user.Email,
      UserName: user.UserName,
      Password: user.Password
    }
    if (tempUser.IsVerified == "1") {
      this.state.userClone.push(tempUser);
    }
    else {
      let index = this.state.userClone.map(object => object._id).indexOf(tempUser._id)
      this.state.userClone.splice(index, 1);
    }
    this.setState({
      userClone: this.state.userClone,
    })
    console.log(this.state.userClone);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  deleteUser = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}libraryUsers/${id}`);
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getUsers();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  submitUsers = () => {
    var url = new URL(`${API_BASE}libraryUsers/updateUserRegStatus`);
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        users: this.state.userClone
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getUsers();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  }

  downloadUsers = () => {
    // console.log('these are users>>>> ',this.state.userClone)
    const fields = ["_id", "IsVerified", "Library", "Email", "UserName"];
    const parser = new Parser({ fields });
    const csv = parser.parse(this.state.userClone);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // alert(JSON.stringify(this.state.userClone))
  }

  viewHandler = (user) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/user/view/${user._id}/${this.props.location.search}`
    );
  };
  deleteHandler = (user) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteUser(user._id)}
        >
          You will not be able to recover this user!
        </SweetAlert>
      ),
    });
  };

  getParentBreadcrumb = () => {
    if (
      this.state.loggedInUser.Role.Name == "Master Admin" ||
      this.state.loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: this.state.library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`,
        },
      ];
    } else {
      return [];
    }
  };


  render() {
    if (this.state.library == 404) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Register Users`}
            parentLink={`${process.env.PUBLIC_URL}/libraries`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
                this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>Library not found!</p>
              <hr />
              <p className="mb-0">
                {(this.state.loggedInUser.Role.Name == "Master Admin" ||
                  this.state.loggedInUser.Role.Name ==
                  "Organization Admin") && (
                    <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                      Back to Libraries
                    </Link>
                  )}
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Register `}
            specialParent={this.getParentBreadcrumb()}
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb
          title={`Register Users`}
          specialParent={this.getParentBreadcrumb()}
        />
        {this.state.alert}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                {/* <div className="card-header">
                  <div className="clearfix"></div>
                </div> */}
                <div className="card-body datatable-react">
                  <p>
                    <strong>Total records: {this.state.users.length}</strong>
                  </p>
                  <Datatable
                    multiSelectOption={true}
                    myData={this.state.users}
                    pageSize={10}
                    pagination={true}
                    class="-striped -highlight"
                    checkBoxHnadler={this.checkBoxHnadler}
                    viewHandler={this.viewHandler}
                    deleteHandler={this.deleteHandler}
                  />
                </div>
                <div class="col-md-9">
                </div>

                {/* <div class="col-md-3" style={{marginBottom: "10px"}}>
               <Button 
               onClick={this.submitUsers}
               disabled={this.state.userClone<=0}>
                Verify All
                </Button>
               </div> */}


                <div className="col-md-3" style={{ marginBottom: "10px" }}>
                  <div className="d-flex justify-content-between gap-2">
                    <Button
                      onClick={this.submitUsers}
                      disabled={this.state.userClone <= 0}
                      style={{ marginRight: "10px" }}
                    >
                      Verify All
                    </Button>
                    <Button
                      onClick={this.downloadUsers}
                      disabled={this.state.userClone <= 0}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default RegisterUserComponent;